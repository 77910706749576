import { BsFillTelephoneFill } from "react-icons/bs";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LOGO from "src/assets/images/logo/logo-footer.svg";

import WeareDUNSCertified from "src/assets/images/WeareDUNSCertified.svg";
import { lanChange } from "src/utils";
import ScrollTopButton from "./ScrollTopButton";
const Footer = () => {
	const { contact, policies } = useSelector((state) => state.homePageInfo.home);
	const language = lanChange();

	return (
		<>
			<footer
				id="download"
				className="edu-footer footer-style-default"
				style={{ backgroundColor: "#3C5DAD" }}
			>
				<div className="footer-top">
					<div className="container eduvibe-animated-shape">
						<div className="row g-5">
							<div className="col-lg-3 col-md-6 col-sm-12 col-12">
								<div className="edu-footer-widget">
									<div
										className="logo"
										style={{
											height: "200px",
											width: "200px",
										}}
									>
										<Link to={process.env.PUBLIC_URL + "/"}>
											<img
												className="logo-light"
												src={LOGO}
												alt="Footer Logo"
												loading="lazy"
											/>
										</Link>
									</div>
									{/* <p className="description">{language.footer.description}</p> */}
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget explore-widget">
									<h5 className="widget-title">
										{language.footer.explore.title}
									</h5>
									<div className="inner">
										<ul className="footer-link link-hover">
											<li>
												<Link to="/about">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.about}
												</Link>
											</li>
											<li>
												<Link to="/contact-us">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.contact}
												</Link>
											</li>
											<li>
												<Link to="/gallery-grid">
													<MdKeyboardDoubleArrowRight
														style={{
															verticalAlign: "sub",
															fontSize: "22px",
														}}
													/>
													{language.footer.explore.galleryAndEvents}
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget quick-link-widget">
									<h5 className="widget-title">
										{language.footer.usefulLinks.title}
									</h5>
									<div className="inner">
										<ul className="footer-link link-hover">
											{policies && Array.isArray(policies)
												? policies.map((policy, index) => (
														<li key={index} style={{ color: "white" }}>
															<Link
																to={`/privacy-details/${policy.slug}`}
															>
																<MdKeyboardDoubleArrowRight
																	style={{
																		verticalAlign: "sub",
																		fontSize: "22px",
																	}}
																/>
																{policy.policy_type}
															</Link>
														</li>
												  ))
												: null}
											{/* <li>
												<Link to="/privacy-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.privacyPolicy}
												</Link>
											</li>
											<li>
												<Link to="/terms-condition">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.termsAndConditions}
												</Link>
											</li>
											<li>
												<Link to="/refund-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.refundPolicy}
												</Link>
											</li>
											<li>
												<Link to="/deletion-policy">
													<i className="icon-Double-arrow"></i>
													{language.footer.usefulLinks.deletionPolicy}
												</Link>
											</li> */}
										</ul>
									</div>
								</div>
							</div>

							<div className="col-lg-3 col-md-6 col-sm-6 col-12">
								<div className="edu-footer-widget">
									<h5 className="widget-title">
										{language.footer.contactInfo.title}
									</h5>
									<div className="inner">
										<div className="widget-information">
											<ul className="information-list">
												<li>
													<CiLocationOn
														style={{
															verticalAlign: "bottom",
															fontSize: "22px",
															marginRight: "10px",
														}}
													/>
													{contact?.location ||
														`Legalized
													Education Bangladesh Ltd 
													Flat 2A, House 88, Road 17/A, Block E, Banani,
													Dhaka`}
												</li>
												<li>
													<BsFillTelephoneFill
														style={{
															verticalAlign: "bottom",
															fontSize: "16px",
															marginRight: "10px",
														}}
													/>
													<a
														href={`tel:${
															contact?.phone || "+880 1879167052"
														}`}
													>
														{contact?.phone || "+880 1879167052"}
													</a>
												</li>
												<li>
													<CiMail
														style={{
															verticalAlign: "bottom",
															fontSize: "20px",
															marginRight: "10px",
														}}
													/>
													<a
														target="_blank"
														href={`mailto: ${
															contact?.email ||
															"legalizededucationbd@gmail.com"
														}`}
														rel="noreferrer"
													>
														{contact?.email ||
															"legalizededucationbd@gmail.com"}
													</a>
												</li>
											</ul>
											<br />
											<ul
												className="social-share d-sm-block"
												style={{ color: "white", width: "100%" }}
											>
												<li
													style={{ color: "white", width: "100%" }}
													className=""
												>
													{language.ETradeLicenseno}: <br /> TRAD/
													DNCC006559/2022
												</li>
												<li style={{ color: "white", width: "100%" }}>
													TIN : 668324194352
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row mt-4 justify-content-between align-items-center">
							<div className="col-lg-5 col-md-5 col-sm-5 col-12">
								<div
									style={{
										color: "white",
										fontSize: "20px",
										fontWeight: "bold",
										marginBottom: "20px",
									}}
								>
									We are D-U-N-S® Certified
								</div>
								<div
									style={{
										width: "30%",
										marginBottom: "20px",
									}}
								>
									<img
										alt="WeareDUNSCertified"
										src={WeareDUNSCertified}
										style={{
											objectFit: "cover",
											width: "100%",
											height: "100%",
											aspectRatio: "auto 100 / 100",
										}}
										loading="lazy"
									/>
								</div>
							</div>
							<div className="col-lg-5 col-md-5 col-sm-5 col-12">
								<ul className="social-share">
									<li
										style={{
											backgroundColor: "#23407B",
											borderRadius: "5px",
										}}
									>
										<a
											href="https://www.facebook.com/legalizededucationbd"
											target="_blank"
											rel="noreferrer"
										>
											<FaFacebookF style={{ verticalAlign: "baseline" }} />
										</a>
									</li>
									<li
										style={{
											backgroundColor: "#23407B",
											borderRadius: "5px",
										}}
									>
										<a
											href="https://www.linkedin.com/in/legalizededucationbd"
											target="_blank"
											rel="noreferrer"
										>
											<FaLinkedinIn style={{ verticalAlign: "baseline" }} />
										</a>
									</li>
									<li
										style={{
											backgroundColor: "#23407B",
											borderRadius: "5px",
										}}
									>
										<a
											href="https://www.instagram.com/legalized_education"
											target="_blank"
											rel="noreferrer"
										>
											<FaInstagram style={{ verticalAlign: "baseline" }} />
										</a>
									</li>
									<li
										style={{
											backgroundColor: "#23407B",
											borderRadius: "5px",
										}}
										className="w-sm-100 w-lg-50"
									>
										<Link
											to="/download-app"
											target="_blank"
											rel="noreferrer"
											className="me-4"
											style={{
												backgroundColor: "#23407B",
												fontWeight: "bold",
												color: "#fff",
												padding: "0px 20px",
												borderRadius: "5px",
												fontSize: "24px",
												display: "block",
												width: "100%",
											}}
										>
											{language.footer.downloadApp}
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div
					className="copyright-area copyright-default"
					style={{
						backgroundColor: "#274A94",
					}}
				>
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="inner text-center">
									<p className="my-3">
										{language.Copyright} {new Date().getFullYear()}.{" "}
										{language.AllRightsReserved}
									</p>
								</div>
							</div>
							{/* <div className="col-lg-6">
								<div className="inner text-end">
									<p>
										Designed & Developed By &nbsp;
										<a
											href="https://sslwireless.com/"
											target="_blank"
											rel="noreferrer"
										>
											SSL Wireless
										</a>
									</p>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</footer>
			<ScrollTopButton />
		</>
	);
};

export default Footer;
