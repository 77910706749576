import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetHomePageInfoQuery } from "src/redux/HomePageInfo/homeApi";
import { setHomePageInfo } from "src/redux/HomePageInfo/homeSlice";
import { userInfo } from "src/redux/auth/authSlice";
import { setAllCourses, setCourseFilter } from "src/redux/course/courseSlice";
import { useGetDashboardQuery } from "src/redux/dashboard/dashboardApi";
import { setDashboard } from "src/redux/dashboard/dashboardSlice";
import { flatDeep } from "src/utils";
import Error from "./Error";
import Loading from "./common/Loading";

export default function Providers({ children }) {
	const { data: home = {}, isLoading, error } = useGetHomePageInfoQuery();
	const user_id = useSelector((state) => state.auth?.user?.user?.id);
	const { data: categoryAndEnrollCourse = {}, isSuccess } = useGetDashboardQuery(user_id);
	const { filter = {} } = useSelector((state) => state.course);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSuccess) {
			dispatch(setDashboard(categoryAndEnrollCourse.data));
			let allCourses = categoryAndEnrollCourse.data.category.map((category) => {
				return category.category_wise_course;
			});
			allCourses = flatDeep(allCourses, 1)
				?.sort((a, b) => a?.course?.order - b?.course?.order)
				?.filter((course) => course?.course?.course_type !== "Professional");

			const allCategory = categoryAndEnrollCourse?.data?.category?.map((category) => {
				return {
					id: category.id,
					label: category.name,
					checked: false,
					totalSubject: category.category_wise_course.length,
				};
			});

			const coursesFilter = allCourses?.map((course) => {
				return {
					id: course?.course?.id,
					label: course?.course?.title,
					checked: false,
					totalSubject: course?.course?.subject_count,
				};
			});

			dispatch(setAllCourses(allCourses));
			dispatch(
				setCourseFilter({
					...filter,
					coursesFilter: coursesFilter,
					categoriesFilter: allCategory,
					courses: allCourses,
				}),
			);
		}
	}, [isSuccess]);

	const despatch = useDispatch();

	useEffect(() => {
		let user = localStorage.getItem("user");
		if (user) {
			user = JSON.parse(user);
			despatch(userInfo(user));
		}
		if (Object.keys(home).length > 0) {
			despatch(setHomePageInfo(home));
		}
	}, [home, despatch]);

	if (isLoading) {
		return <Loading />;
	}

	if (error) {
		return <Error />;
	}
	return children;
}
