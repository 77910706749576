import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// http://localhost:3000/my-course/1/subject/4/chapters/33?lesson_id=144&chapter_type=MCQ
// http://localhost:3000/my-course/1/subject/4/chapters/33?lesson_id=144&chapter_type=MCQ&course_type=null
const SEO = ({ title = "" }) => {
	return (
		<Helmet>
			<meta charSet="utf-8" />
			<title>{title} </title>
			<meta
				name="keywords"
				content="Legalized, Legalized Education, lms, Bar Council, High Court,High Court Order"
			/>
			<meta property="og:title" content="Legalized Education Bangladesh Ltd." />
			<meta property="og:type" content="website" />
			<meta property="og:image" content="%PUBLIC_URL%/images/logo/logo.png" />
			<meta property="og:url" content="https://legalizedbd.com/" />
			<meta property="og:site_name" content="Legalized Education Bangladesh Ltd." />
			<meta
				name="description"
				content="Discover the world of legal education like you have never experienced before. Start your journey with us today."
			/>
			<meta name="viewport" content="width=device-width, initial-scale=1" />
			<meta name="theme-color" content="#000000" />
		</Helmet>
	);
};
SEO.propTypes = {
	title: PropTypes.string,
};

export default SEO;
