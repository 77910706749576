import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// Import Css Here
import "./assets/scss/style.scss";

import { lazy } from "react";
import Error from "./components/Error";
import Layout from "./components/Layout";
import PrivateRoute from "./components/PrivateRoute";
import InstructorDetailsPage from "./pages/InstructorDetails";

const WishlistPage = lazy(() => import("./pages/Wishlist"));
const AboutPage = lazy(() => import("./pages/About"));
const AboutReadMore = lazy(() => import("./pages/AboutReadMore"));
const AllCourseFilterPages = lazy(() => import("./pages/AllCourseFilter"));
const Chapters = lazy(() => import("./pages/Chapters"));
const ContactUsPage = lazy(() => import("./pages/ContactUs"));
const CorporatePage = lazy(() => import("./pages/Corporate"));
const CoursePage = lazy(() => import("./pages/Course"));
const CourseDetails = lazy(() => import("./pages/CourseDetails"));
const DeviceManagerPage = lazy(() => import("./pages/DeviceManager"));
const Download = lazy(() => import("./pages/Download"));
const EventDetailsPage = lazy(() => import("./pages/EventDetails"));
const EventPage = lazy(() => import("./pages/Events"));
const GalleryGridPage = lazy(() => import("./pages/GalleryGrid"));
const GloballySpeakingPage = lazy(() => import("./pages/GloballySpeaking"));
const Landing = lazy(() => import("./pages/Landing"));
const LegalProfessionalPage = lazy(() => import("./pages/LegalProfessional"));
const LegalProfessionalCourseDetailsPage = lazy(() =>
	import("./pages/LegalProfessionalCourseDetails"),
);
const MyCertificatesPage = lazy(() => import("./pages/MyCertificates"));
const MyCourse = lazy(() => import("./pages/MyCourse"));
const MyProfilePage = lazy(() => import("./pages/MyProfile"));
const OrderHistoryPage = lazy(() => import("./pages/OrderHistory"));
const PrivacyDetails = lazy(() => import("./pages/PrivacyDetails"));
const QuestionsAnswerPage = lazy(() => import("./pages/QuestionsAnswer"));
const Quiz = lazy(() => import("./pages/Quiz"));
const QuizViewPage = lazy(() => import("./pages/QuizView"));
const RecordedSessionsPage = lazy(() => import("./pages/RecordedSessions"));
const RecordedSessionsListPage = lazy(() => import("./pages/RecordedSessionsListPage"));
const StudentDashboard = lazy(() => import("./pages/StudentDashboard"));
const Subject = lazy(() => import("./pages/Subjects"));
const ViewWrittenExamPage = lazy(() => import("./pages/ViewWrittenExam"));
const WrittenExam = lazy(() => import("./pages/WrittenExam"));

function App() {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route exact path="/" element={<Landing />} />
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/gallery-grid"}`}
						element={<GalleryGridPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/privacy-details/:slug"}`}
						element={<PrivacyDetails />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/instructor-details"}`}
						element={<InstructorDetailsPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/about"}`}
						element={<AboutPage />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/courses"}`}
						element={<AllCourseFilterPages />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/legal-professional/courses"}`}
						element={<PrivateRoute children={<LegalProfessionalPage />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL +
							"/course/:slug/legal-professional-course-details/:id"
						}`}
						element={<PrivateRoute children={<LegalProfessionalCourseDetailsPage />} />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/course/:slug"}`}
						element={<CoursePage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/course/:slug/course-details/:id"}`}
						element={<CourseDetails />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/download-app"}`}
						element={<Download />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/contact-us"}`}
						element={<ContactUsPage />}
					/>

					{/* login required */}
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/wishlist"}`}
						element={<PrivateRoute children={<WishlistPage />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/student-dashboard"}`}
						element={<PrivateRoute children={<StudentDashboard />} />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/my-course/:course_id"}`}
						element={<PrivateRoute children={<MyCourse />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL + "/my-course/:course_id/subject/:subject_id"
						}`}
						element={<PrivateRoute children={<Subject />} />}
					/>
					<Route
						path={`${
							process.env.PUBLIC_URL +
							"/my-course/:course_id/subject/:subject_id/chapters/:chapter_id"
						}`}
						element={<PrivateRoute children={<Chapters />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/quiz/:exam_id"}`}
						element={<PrivateRoute children={<Quiz />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/questions-answer/:exam_id"}`}
						element={<PrivateRoute children={<QuestionsAnswerPage />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL + "/my-course/:course_id/quiz-view/:exam_id"
						}`}
						element={<PrivateRoute children={<QuizViewPage />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL +
							"/my-course/:course_id/subject/:subject_id/chapters/:chapter_id/quiz-view/:exam_id"
						}`}
						element={<PrivateRoute children={<QuizViewPage />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL + "/my-course/:course_id/written-exam/:exam_id"
						}`}
						element={<PrivateRoute children={<WrittenExam />} />}
					/>
					<Route
						exact
						path={`${
							process.env.PUBLIC_URL +
							"/my-course/:course_id/view-written-exam/:exam_id"
						}`}
						element={<PrivateRoute children={<ViewWrittenExamPage />} />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/my-profile"}`}
						element={<PrivateRoute children={<MyProfilePage />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/order-history"}`}
						element={<PrivateRoute children={<OrderHistoryPage />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/device-manager"}`}
						element={<PrivateRoute children={<DeviceManagerPage />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/my-certificates"}`}
						element={<PrivateRoute children={<MyCertificatesPage />} />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/corporate/:corporate_id"}`}
						element={<PrivateRoute children={<CorporatePage />} />}
					/>

					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/events"}`}
						element={<EventPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/events/:events_id"}`}
						element={<EventDetailsPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/globally-speaking"}`}
						element={<GloballySpeakingPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/recorded-sessions"}`}
						element={<RecordedSessionsPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/recorded-sessions/:id"}`}
						element={<RecordedSessionsListPage />}
					/>
					<Route
						exact
						path={`${process.env.PUBLIC_URL + "/about-read-more"}`}
						element={<AboutReadMore />}
					/>

					<Route path="*" element={<Error />} />
				</Routes>
			</Layout>
		</Router>
	);
}

export default App;
