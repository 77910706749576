import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect } from "react";
import ReactFacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { useDispatch } from "react-redux";
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";
import { toast } from "react-toastify";
import { useGetSocialLoginMutation } from "src/redux/auth/authApi";
import { userModalType } from "src/redux/auth/authSlice";

const SocialLoginButtons = ({ type, setSignUp, setUserInfo }) => {
	const [getSocialLogin, { isLoading, data }] = useGetSocialLoginMutation();
	const [user, setUser] = React.useState(null);
	const dispatch = useDispatch();
	// const fbRaf = useRef(null);
	const login = useGoogleLogin({
		onSuccess: async (codeResponse) => {
			const response = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
				headers: {
					Authorization: `${codeResponse.token_type} ${codeResponse.access_token}`,
				},
			});
			const userData = await response.json();

			setUser(userData);
			await getSocialLogin({
				token: codeResponse.access_token,
				provider: "google",
			});
		},
	});

	const responseFacebook = async (response) => {
		console.log("responseFacebook", response);
		setUser(response);
		await getSocialLogin({
			token: response.accessToken,
			provider: "google",
		});
	};
	useEffect(() => {
		if (data?.status === "success") {
			if (data.data?.is_new) {
				dispatch(userModalType("sign-up"));
				setSignUp({
					name: user?.name,
					email: user?.email,
				});
			} else {
				setUserInfo(data);
			}
		} else {
			toast.error(data?.message);
		}
	}, [isLoading]);
	return (
		<>
			<p className="p-0 m-0 text-center">or</p>
			<ReactFacebookLogin
				buttonStyle={{ padding: "6px" }}
				appId={process.env.REACT_APP_FACEBOOK_APP_ID}
				autoLoad={false}
				fields="name,email,picture"
				callback={responseFacebook}
				render={(renderProps) => (
					<div onClick={renderProps.onClick}>
						<FacebookLoginButton />
					</div>
				)}
			/>
			<GoogleLoginButton onClick={login} className="my-4" />
		</>
	);
};

export default SocialLoginButtons;
