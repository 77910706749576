import { useEffect, useState } from "react";
import { lanChange } from "src/utils";

const OTP = ({ time, isResendDisabled, setIsResendDisabled, resetOTPSubmit, setOtp }) => {
	const [timeLeft, setTimeLeft] = useState(150);

	useEffect(() => {
		if (timeLeft === 0) {
			setIsResendDisabled(false);
		}
		if (!isResendDisabled) {
			setTimeLeft(time);
		}
		const timer = setTimeout(() => {
			if (timeLeft > 0) {
				setTimeLeft(timeLeft - 1);
			}
		}, 1000);
		return () => clearTimeout(timer);
	}, [timeLeft, isResendDisabled]);

	const handleResendOtp = () => {
		// setOtp({});
		setIsResendDisabled(true);
		setTimeLeft(150);
		resetOTPSubmit();
	};

	// Render a countdown 00.00 format
	const renderTime = () => {
		const minutes = Math.floor(timeLeft / 60);
		const seconds = timeLeft - minutes * 60;
		return `${minutes < 10 ? `0${minutes}` : minutes}:${
			seconds < 10 ? `0${seconds}` : seconds
		}`;
	};

	const language = lanChange();

	return (
		<p>
			{language.HavingTroubleRequestANewOTPIn}
			{isResendDisabled ? (
				" " + renderTime() + " min"
			) : (
				<p>
					{language.DidNotReceiveTheCode}
					<span role="button" className="text-primary" onClick={handleResendOtp}>
						&nbsp; {language.Resend}
					</span>
				</p>
			)}
		</p>
	);
};

export default OTP;
