import React from "react";
import { Badge, Button, ButtonGroup, Col, Dropdown, Offcanvas, Row } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { ReactComponent as Read } from "src/assets/images/read.svg";
import { ReactComponent as Unread } from "src/assets/images/unread.svg";

import CustomButton from "src/components/common/CustomButton";
export default function MobileNav({
	navBar,
	isActiveFunc,
	isLoadingAllNotificationsAsRead,
	markAllNotificationsAsRead,
	user,
}) {
	return (
		<div>
			<Offcanvas.Header closeButton className="align-items-center">
				<NavLink to="/">
					<img
						className="logo-light"
						alt={navBar.logo.alt}
						src={navBar.logo.src}
						width={navBar.logo.width}
						height={navBar.logo.height}
						loading="lazy"
					/>
				</NavLink>
				<div
					style={{
						display: "flex",
						justifyContent: "end",
						alignItems: "center",
						width: "100%",
					}}
				>
					{navBar.isLogin && (
						<>
							<Col sm={1} md={2} lg={1}>
								<Dropdown as={ButtonGroup}>
									<Dropdown.Toggle
										split
										variant="success"
										id="dropdown-split-basic"
										style={{
											backgroundColor: "transparent",
											border: "none",
											color: "white",
										}}
									>
										<Button
											variant="success"
											style={{
												backgroundColor: "transparent",
												border: "none",
												color: "white",
											}}
										>
											<p className="m-0" style={{ position: "relative" }}>
												{navBar.others[3].name}
												<Badge
													bg="danger"
													style={{
														position: "absolute",
														top: -10,
														right: -15,
														borderRadius: "50%",
														border: "2px solid white",
													}}
												>
													{navBar.others[3]?.options?.length}
												</Badge>
											</p>
										</Button>
									</Dropdown.Toggle>

									<Dropdown.Menu
										style={{
											width: "85vw",
											padding: "20px",
											borderRadius: "10px",
										}}
									>
										<div
											style={{
												fontWeight: 600,
												fontSize: "20px",
												paddingBottom: "10px",
											}}
										>
											Notifications
										</div>
										{navBar.others[3]?.options?.length > 0 ? (
											navBar.others[3]?.options?.map(
												({ children, ...option }, i) => {
													return (
														<Dropdown.Item
															key={i}
															{...option}
															style={{
																fontWeight: 600,
																fontSize: "16px",
																whiteSpace: "inherit",
																padding: "10px 0px",
																borderTop: "1px solid #e5e5e5",
															}}
														>
															<div className="d-flex">
																<div className="me-4">
																	{option?.is_read ? (
																		<Read
																			style={{
																				width: 40,
																				height: 40,
																			}}
																		/>
																	) : (
																		<Unread
																			style={{
																				width: 40,
																				height: 40,
																			}}
																		/>
																	)}
																</div>

																<div>
																	<p
																		className="m-0 p-0"
																		style={{
																			wordWrap: "break-word",
																			color: "black",
																		}}
																	>
																		{children}
																	</p>
																	<p
																		className="m-0 p-0"
																		style={{
																			wordWrap: "break-word",
																		}}
																	>
																		{option.created_at}
																	</p>
																</div>
															</div>
														</Dropdown.Item>
													);
												},
											)
										) : (
											<h6 className="text-center" style={{ color: "gray" }}>
												No notifications found!
											</h6>
										)}
										{navBar.others[3]?.options?.length > 0 ? (
											<div className="text-center">
												<CustomButton
													disabled={isLoadingAllNotificationsAsRead}
													onClick={() =>
														markAllNotificationsAsRead({
															id: "all",
														})
													}
													className=""
													style={{
														width: "100%",
														background: "#525fe1",
														padding: "15px",
														borderRadius: "5px",
													}}
													btnTextStyle={{
														fontSize: "16px",
														color: false ? "gray" : "white",
														fontWeight: 600,
													}}
												>
													Mark as read
												</CustomButton>
											</div>
										) : null}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
							<Col sm={1} md={2} lg={1} className="ms-1 me-4">
								<Dropdown
									className="w-100"
									as={ButtonGroup}
									onSelect={navBar.others[4].onSelect}
								>
									<Dropdown.Toggle
										split
										variant="success"
										id="dropdown-split-basic"
										className="w-100"
										style={{
											backgroundColor: "transparent",
											border: "none",
											color: "white",
										}}
									>
										<Button
											variant="success"
											className="w-100"
											style={{
												backgroundColor: "transparent",
												border: "none",
												color: "white",
											}}
										>
											{navBar.others[4].name}
										</Button>
									</Dropdown.Toggle>

									<Dropdown.Menu
										style={{
											minWidth: "100%",
											borderRadius: 10,
											padding: "10px",
										}}
									>
										{navBar.others[4]?.options?.map(
											({ children, ...option }, i, self) => {
												const isLast = self.length - 1 === i;
												return (
													<Dropdown.Item
														key={i}
														{...option}
														style={{
															fontWeight: 600,
															fontSize: "16px",
															padding: "5px 15px",
															color: isLast ? "red" : "black",
														}}
													>
														{children}
														{isLast && (
															<BsArrowRight
																style={{
																	marginLeft: "50%",
																}}
															/>
														)}
													</Dropdown.Item>
												);
											},
										)}
									</Dropdown.Menu>
								</Dropdown>
							</Col>
						</>
					)}

					{!user && (
						<Col
							sm={12}
							className="mobile-menu-bar justify-content-end w-100 align-items-center d-flex d-xl-none"
						>
							<Col sm={3} md={1} lg={1}>
								<button
									className="btn btn-outline-primary header-button me-4"
									onClick={navBar.others[0].onClick}
									type={navBar.others[0].type}
									style={{
										padding: "8px 10px",
										fontSize: "small",
										fontWeight: 700,
									}}
								>
									{navBar.others[0].text}
								</button>
							</Col>
							<Col sm={6} md={3} lg={3}>
								<button
									className="btn btn-primary header-button me-4"
									onClick={navBar.others[1].onClick}
									type={navBar.others[1].type}
									style={{
										padding: "8px 10px",
										fontSize: "small",
										fontWeight: 700,
									}}
								>
									{navBar.others[1].text}
									{/* Join */}
								</button>
							</Col>
						</Col>
					)}
				</div>
			</Offcanvas.Header>
			<Offcanvas.Body>
				<Row className="justify-content-center align-items-center">
					<Col sm={12} className="mb-4">
						<select
							className=" form-select form-select-lg border-0 lan-select me-4"
							aria-label=".form-select-lg example"
							onChange={navBar.others[2].onChange}
							value={navBar.others[2].value}
							style={{
								fontWeight: 600,
								fontSize: "16px",
								border: "1px solid #818181",
							}}
						>
							{navBar.others[2].options?.map((option) => (
								<option
									key={option.id}
									className="lan-option px-2"
									value={option.value}
									style={{
										fontWeight: 600,
										fontSize: "16px",
									}}
								>
									{option.text}
								</option>
							))}
						</select>
					</Col>
					{/* <Col sm={12} className="mb-4">
						<Search navBar={navBar} className="w-100" />
					</Col> */}
					<Col sm={12} className="mb-4 justify-content-center align-items-center"></Col>
					<Col sm={12}>
						<Row className="justify-content-between align-items-center">
							<Col sm={12} className="d-flex align-items-center mb-4">
								<div className="header-menu-bar w-100">
									<div className="quote-icon quote-user  w-100"></div>
								</div>
							</Col>
							{navBar.nav.map((path, index) => {
								if (path.type === "dropdown") {
									return (
										<Dropdown
											className="d-flex align-items-center justify-content-center fs-5 mb-4"
											key={index}
											style={{
												textAlign: "center",
											}}
										>
											<Dropdown.Toggle
												as={CustomToggle}
												id="dropdown-custom-components"
												className="fs-5"
											>
												{path?.text}
											</Dropdown.Toggle>

											<Dropdown.Menu>
												{path.options?.map((option, i) => (
													<Dropdown.Item
														eventKey={option?.id}
														className="fs-5"
														onClick={option?.onClick}
													>
														{option.children}
													</Dropdown.Item>
												))}
											</Dropdown.Menu>
										</Dropdown>
									);
								}
								return (
									<Col key={index} sm={12} className="text-center mb-4">
										<NavLink
											to={path.link}
											onClick={path.onClick}
											className={` ${isActiveFunc}`}
										>
											{path.text}
										</NavLink>
									</Col>
								);
							})}
						</Row>
					</Col>
				</Row>
			</Offcanvas.Body>
		</div>
	);
}
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
		<RiArrowDownSLine
			style={{
				fontSize: "20px",
				marginLeft: "2px",
			}}
		/>
	</a>
));
