import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isModalOpen, userModalType } from "src/redux/auth/authSlice";
import { lanChange } from "src/utils";
import BreadcrumbOne from "./common/BreadcrumbOne";
import SEO from "./common/SEO";

const PrivateRoute = ({ children }) => {
	const isLoggedIn = useSelector((state) => state.auth.user);
	const navigate = useNavigate();
	const despatch = useDispatch();
	const language = lanChange();
	if (isLoggedIn) {
		return <>{children}</>;
	} else {
		// return navigate("/login");
		despatch(userModalType("login"));
		despatch(isModalOpen("login"));
		return (
			<div
				style={{
					height: "80vh",
				}}
			>
				<SEO title={language.category.breadcrumb.title} />
				<BreadcrumbOne rootUrl="/" parentUrl={language.category.breadcrumb.parent} />
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						padding: "100px 0px",
					}}
				>
					<h6 className="text-center m-0 p-0">Please Login to Continue.</h6>
				</div>
			</div>
		);
	}
};

export default PrivateRoute;
