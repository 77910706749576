import React, { useEffect, useState } from "react";
import { Badge, Button, ButtonGroup, Col, Dropdown, Form, Offcanvas } from "react-bootstrap";
import { BsArrowRight, BsBell } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { ReactComponent as Read } from "src/assets/images/read.svg";
import { ReactComponent as Unread } from "src/assets/images/unread.svg";

import CustomButton from "src/components/common/CustomButton";
import HeaderSticky from "src/components/common/HeaderSticky";
import {
	useBusinessInquiryPostMutation,
	useCorporateLoginMutation,
	useForgotPasswordMutation,
	useLoginMutation,
	useLogoutMutation,
	useOtpVerificationMutation,
	usePasswordCreateMutation,
	usePasswordResetMutation,
	useSendOtpMutation,
	useSignUpMutation,
} from "src/redux/auth/authApi";
import {
	isModalOpen,
	setIsForgotPassword,
	userInfo,
	userModalType,
} from "src/redux/auth/authSlice";
import {
	useMarkAllNotificationsAsReadMutation,
	useUnreadNotificationsQuery,
} from "src/redux/notifications/notificationsApi";
import { lanChange } from "src/utils";

import moment from "moment";
import ModalAuth from "../ModalAuth";
import MobileNav from "./MobileNav";

export default function NavBar({ styles, disableSticky, ref }) {
	const [show, setShow] = useState(false);
	const [otp, setOtp] = useState({});
	const { modalType, modal, isForgetPassword } = useSelector((state) => state.auth);
	const site_logo = useSelector((state) => state.homePageInfo.home.site_logo);
	const favicon = useSelector((state) => state.homePageInfo.home.favicon);
	const dispatch = useDispatch();
	const location = useLocation();
	const language = lanChange();

	const notShowLang = [
		"/gallery-grid",
		"/privacy-details/:slug",
		"/about",
		"/download-app",
		"/contact-us",
		"/event",
		"/",
	];

	const notShow = notShowLang.includes(location.pathname);

	const [businessInquiry, setBusinessInquiry] = useState({
		fullname: "",
		email: "",
		inquiry_details: "",
	});
	const [signup, setSignUp] = useState({
		name: "",
		email: "",
		phone: "",
		username: "",
		dob: "",
		otp_for: "",
	});
	const [logIn, setLogin] = useState({
		username: "",
		password: "",
	});
	const [phoneNumber, setPhoneNumber] = useState("");
	const [createPassword, setCreatePassword] = useState({
		password: "",
		c_password: "",
	});
	let [searchParams, setSearchParams] = useSearchParams();
	const user = useSelector((state) => state.auth?.user?.user);
	const type = useSelector((state) => state.auth?.user?.type);
	const navigate = useNavigate();

	// api calls
	const [signUp, { isLoading: isLoadingSignUp, data: signUpRes }] = useSignUpMutation();
	const [businessInquiryPost, { isLoading: isLoadingBusinessInquiry, data: businessInquiryRes }] =
		useBusinessInquiryPostMutation();
	const [otpVerification, { isLoading: isLoadingOtpVerification, data: otpVerificationRes }] =
		useOtpVerificationMutation();
	const [corporateLogin, { isLoading: isLoadingCorporateLogin, data: corporateLoginRes }] =
		useCorporateLoginMutation();
	const [passwordCreate, { isLoading: isLoadingPasswordCreate, data: passwordCreateRes }] =
		usePasswordCreateMutation();
	const [login, { isLoading: isLoadingLogin, data: loginRes }] = useLoginMutation();
	const [forgotPassword, { isLoading: isLoadingForgotPassword, data: forgotPasswordRes }] =
		useForgotPasswordMutation();
	const [sendOtp, { isLoading: isLoadingSendOtp, data: sendOtpRes }] = useSendOtpMutation();
	const [passwordReset, { isLoading: isLoadingPasswordReset, data: passwordResetRes }] =
		usePasswordResetMutation();
	const [logout, { isLoading: isLoadingLogout, data: logoutPasswordRes }] = useLogoutMutation();
	const [
		markAllNotificationsAsRead,
		{ isLoading: isLoadingAllNotificationsAsRead, data: AllNotificationsAsReadRes },
	] = useMarkAllNotificationsAsReadMutation();
	const {
		isLoading: isLoadingUnreadNotifications,
		isSuccess: isSuccessUnreadNotifications,
		data: unreadNotificationsData,
	} = useUnreadNotificationsQuery("", { skip: !user });

	const onNavigate = (link, ...option) => {
		if (show) {
			setShow(false);
		}
		navigate(link, option);
	};

	// nav bar data
	const navBar = {
		isLogin: user ? true : false,
		logo: {
			src: site_logo,
			alt: "Main Logo",
			height: "auto",
			width: 80,
			link: process.env.PUBLIC_URL + "/",
		},
		search: {
			onEnter: (e) => {
				e.preventDefault();
				setSearchParams("/courses?search=" + e.target[0].value, {
					state: { search: e.target[0].value },
				});

				onNavigate("/courses?search=" + e.target[0].value, {
					state: { search: e.target[0].value },
				});
			},
			field: {
				type: "text",
				placeholder: language.navbar.search,
			},
		},
		nav: [
			{
				id: 2,
				type: "text",
				text: language.july36,
				link: "http://36july.legalizedbd.com/",
				rel: "noreferrer",
				target: "_blank",
				onClick: () => onNavigate("http://36july.legalizedbd.com/"),
			},
			{
				id: 1,
				type: "text",
				text: language.navbar.courses,
				link: "/courses",
				onClick: () => onNavigate("/courses"),
			},
			{
				id: 3,
				type: "text",
				text: language.navbar.about,
				link: "/about",
				onClick: () => onNavigate("/about"),
			},
			{
				id: 1,
				type: "text",
				text: language.Gallery,
				link: "/gallery-grid",
				onClick: () => onNavigate("/gallery-grid"),
			},

			{
				id: 4,
				type: "text",
				text: language.navbar.contactUs,
				link: "/contact-us",
				onClick: () => onNavigate("/contact-us"),
			},
			{
				id: 1,
				type: "dropdown",
				onClick: (eventKey) => {
					// console.log(eventKey);
					// if (eventKey === "logOut") {
					// 	logout();
					// }
				},
				text: language.Resources,
				options: [
					{
						id: 1,
						eventKey: "events",
						children: language.Events,
						value: "events",
						onClick: () => onNavigate("/events"),
						link: "/events",
					},
					{
						id: 2,
						eventKey: "globally-speaking",
						children: language.GloballySpeaking,
						value: "globally-speaking",
						onClick: () => onNavigate("/globally-speaking"),
						link: "/globally-speaking",
					},
					{
						id: 3,
						eventKey: "recorded-sessions",
						children: language.RecordedSessions,
						value: "recorded-sessions",
						onClick: () => onNavigate("/recorded-sessions"),
						link: "/recorded-sessions",
					},
				],
			},
		],
		others: [
			{
				id: 1,
				type: "button",
				text: language.navbar.login,
				onClick: () => {
					dispatch(isModalOpen(true));
					dispatch(userModalType("login"));
				},
			},
			{
				id: 2,
				type: "button",
				text: language.navbar.joinNow,

				onClick: () => {
					dispatch(isModalOpen(true));
					dispatch(userModalType("business-inquiry"));
				},
			},
			{
				id: 3,
				type: "select",
				value: localStorage.getItem("lang") || "en",
				onChange: (event) => {
					localStorage.setItem("lang", event.target.value);
					window.location.reload();
				},
				options: [
					{
						id: 1,
						text: "English",
						value: "en",
					},
					{
						id: 2,
						text: "বাংলা",
						value: "bn",
					},
				],
			},
			{
				id: 4,
				type: "dropdown",
				onSelect: (e) => console.log(e),
				name: (
					<BsBell
						style={{
							width: "25px",
							height: "25px",
							color: "black",
						}}
					/>
				),
				options: isSuccessUnreadNotifications ? [...unreadNotificationsData.data] : [],
			},
			{
				id: 5,
				type: "dropdown",
				onSelect: (eventKey) => {},
				name: (
					<div className="d-flex align-items-center" style={{ width: 40, height: 40 }}>
						<img
							src={
								user?.profile_image ??
								`https://ui-avatars.com/api/?name=${user?.name}&size=50&rounded=true&color=fff&background=fc6369`
							}
							alt="user"
							style={{
								height: "100%",
								width: "100%",
								borderRadius: "50%",
							}}
							loading="lazy"
						/>
					</div>
				),
				options:
					type === "corporate"
						? [
								{
									id: 1,
									eventKey: "myprofile",
									children: user?.name
										? String(user?.name)
												.split(" ")
												.slice(0, 1)
												.join(" ")
												.charAt(0)
												.toUpperCase() + user?.name?.slice(1)
										: language.navbar.personalInformation,
									value: "myprofile",
									onClick: () => onNavigate("/my-profile"),
								},
								{
									id: 2,
									eventKey: "mycertificates",
									children: language.navbar.mycertificates,
									value: "mycertificates",
									onClick: () => onNavigate("/my-certificates"),
								},
								{
									id: 6,
									eventKey: "logOut",
									children: language.navbar.logout,
									value: "logout",
									onClick: logout,
								},
						  ]
						: [
								{
									id: 1,
									eventKey: "myprofile",
									children: user?.name
										? String(user?.name)
												.split(" ")
												.slice(0, 1)
												.join(" ")
												.charAt(0)
												.toUpperCase() + user?.name?.slice(1)
										: language.navbar.personalInformation,
									value: "myprofile",
									onClick: () => onNavigate("/my-profile"),
								},
								{
									id: 7,
									eventKey: "wishlist",
									children: language?.Wishlist,
									value: "wishlist",
									onClick: () => onNavigate("/wishlist"),
								},
								{
									id: 2,
									eventKey: "mycourses",
									children: language.navbar.myCourses,
									value: "mycourses",
									onClick: () => onNavigate("/student-dashboard"),
								},
								{
									id: 2,
									eventKey: "mycertificates",
									children: language.navbar.mycertificates,
									value: "mycertificates",
									onClick: () => onNavigate("/my-certificates"),
								},

								{
									id: 3,
									eventKey: "orderhistory",
									children: language.navbar.orderHistory,
									value: "orderhistory",
									onClick: () => onNavigate("/order-history"),
								},
								{
									id: 4,
									eventKey: "changepassword",
									children: language.navbar.changePassword,
									value: "changepassword",
									onClick: () => {
										dispatch(setIsForgotPassword(true));
										dispatch(isModalOpen(true));
										dispatch(userModalType("forget-password"));
									},
								},
								{
									id: 5,
									eventKey: "devicemanager",
									children: language.navbar.deviceManager,
									value: "devicemanager",
									onClick: () => onNavigate("/device-manager"),
								},
								{
									id: 6,
									eventKey: "logOut",
									children: language.navbar.logout,
									value: "logout",
									onClick: logout,
								},
						  ],
			},
		],
	};

	function setUserInfo(loginData) {
		// dispatch(userInfo(loginData?.data));
		localStorage.setItem("token", loginData?.data?.token);
		localStorage.setItem("user", JSON.stringify(loginData?.data));
		setLogin({
			username: "",
			password: "",
		});
		dispatch(isModalOpen(false));
		if (location.pathname !== "/") {
			window.location.href = location.pathname;
		} else {
			if (loginData?.data?.enroll_status) {
				window.location.href = "/student-dashboard";
			} else {
				window.location.href = "/";
			}
		}
	}
	// SET ICON
	useEffect(() => {
		if (favicon) {
			const icon = document.querySelector(".favicon");
			icon.href = favicon;
		}
	}, [favicon]);
	// sign up
	useEffect(() => {
		if (signUpRes?.status === "fail") {
			toast.error(signUpRes?.message || "Sign Up failed");
		}
		if (signUpRes?.status === "success") {
			toast.success(signUpRes.message);

			dispatch(userModalType("otp"));
		}
	}, [isLoadingSignUp]);
	// Business Inquiry
	useEffect(() => {
		if (businessInquiryRes?.status === "fail") {
			toast.error(businessInquiryRes?.message);
		}
		if (businessInquiryRes?.status === "success") {
			toast.success(businessInquiryRes.message);

			setBusinessInquiry({
				fullname: "",
				email: "",
				inquiry_details: "",
			});
			dispatch(isModalOpen(false));
		}
	}, [isLoadingBusinessInquiry]);

	// otp verification
	useEffect(() => {
		if (otpVerificationRes?.status === "fail") {
			toast.error(otpVerificationRes?.message);
			setOtp({});

			// dispatch(userModalType("create-password"));
		}
		if (otpVerificationRes?.status === "success") {
			toast.success(otpVerificationRes?.message || "OTP verified successfully");
			setOtp({});
			setSignUp({
				name: "",
				email: "",
				phone: "",
				username: "",
				dob: "",
				otp_for: "",
			});
			setPhoneNumber("");

			dispatch(userModalType("create-password"));
		}
	}, [isLoadingOtpVerification]);
	// Corporate Login
	useEffect(() => {
		if (corporateLoginRes?.status === "fail") {
			toast.error(corporateLoginRes?.message);
			setOtp({});

			// dispatch(userModalType("create-password"));
		}
		if (corporateLoginRes?.status === "success") {
			toast.success(corporateLoginRes?.message || "OTP verified successfully");
			setOtp({});
			setSignUp({
				name: "",
				email: "",
				phone: "",
				username: "",
				dob: "",
				otp_for: "",
			});
			setPhoneNumber("");
			dispatch(
				userInfo({
					user: {
						user: corporateLoginRes?.data?.corporateUser,
					},
				}),
			);
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			localStorage.setItem("token", corporateLoginRes?.data?.access_token);
			localStorage.setItem(
				"user",
				JSON.stringify({
					user: corporateLoginRes?.data?.corporateUser,
					token: corporateLoginRes?.data?.access_token,
					type: corporateLoginRes?.data?.type,
				}),
			);
			dispatch(isModalOpen(false));
			window.location.href = "/legal-professional/courses";
		}
	}, [isLoadingCorporateLogin]);

	// password create
	useEffect(() => {
		if (passwordCreateRes?.status === "fail") {
			toast.error(passwordCreateRes?.message);
		}
		if (passwordCreateRes?.status === "success") {
			toast.success(passwordCreateRes?.message || "Password created successfully");
			setCreatePassword({
				password: "",
				c_password: "",
			});

			dispatch(
				userInfo({
					user: passwordCreateRes?.data,
				}),
			);
			localStorage.setItem("token", passwordCreateRes?.data?.token);
			localStorage.setItem("user", JSON.stringify(passwordCreateRes?.data));
			setLogin({
				username: "",
				password: "",
			});
			dispatch(isModalOpen(false));
			window.location.href = "/";
		}
	}, [isLoadingPasswordCreate]);

	// login
	useEffect(() => {
		if (loginRes?.status === "fail") {
			toast.error(loginRes?.message || "Login failed");
		}
		if (loginRes?.status === "success") {
			toast.success(loginRes?.message || "Login successfully");
			setUserInfo(loginRes);
		}
	}, [isLoadingLogin]);

	// forgot password
	useEffect(() => {
		if (forgotPasswordRes?.status === "fail") {
			toast.error(forgotPasswordRes?.message || "Forgot password failed");
		}
		if (forgotPasswordRes?.status === "success") {
			toast.success(forgotPasswordRes?.message || "Forgot password successfully");

			dispatch(userModalType("otp"));
		}
	}, [isLoadingForgotPassword]);
	// sendOtp corporet
	useEffect(() => {
		if (sendOtpRes?.status === "fail") {
			toast.error(sendOtpRes?.message || "Send OTP Failed");
		}
		if (sendOtpRes?.status === "success") {
			toast.success(sendOtpRes?.message || "Send OTP Successfully");
			dispatch(userModalType("legal-professional-otp"));
		}
	}, [isLoadingSendOtp]);
	// reset password
	useEffect(() => {
		if (passwordResetRes?.status === "fail") {
			toast.error(passwordResetRes?.message || "Reset password failed");
		}
		if (passwordResetRes?.status === "success") {
			toast.success(passwordResetRes?.message || "Reset password successfully");
			setCreatePassword({
				password: "",
				c_password: "",
			});
			if (isForgetPassword) {
				dispatch(isModalOpen(false));
				dispatch(setIsForgotPassword(false));
			} else {
				dispatch(userInfo(passwordResetRes?.data));
				localStorage.setItem("token", passwordResetRes?.data?.token);
				localStorage.setItem("user", JSON.stringify(passwordResetRes?.data));
				setLogin({
					username: "",
					password: "",
				});
				dispatch(isModalOpen(false));
				window.location.href = "/";
			}
		}
	}, [isLoadingPasswordReset]);

	// logout
	useEffect(() => {
		if (logoutPasswordRes?.status === "fail") {
			toast.error(logoutPasswordRes?.message);
		}
		if (logoutPasswordRes?.status === "success") {
			toast.success(logoutPasswordRes?.message || "Logout successfully");
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			// dispatch(userInfo(null));
			window.location.href = "/";
		}
	}, [isLoadingLogout]);

	useEffect(() => {
		if (user?.status === 0) {
			logout();
		}
	}, [user]);

	const sticky = HeaderSticky(118);
	const classes = sticky ? "sticky" : "";
	const stickyStatus = disableSticky ? "" : " header-sticky";

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	function isEighteenYearsAgo(dob) {
		const today = new Date();
		const birthDate = new Date(dob);
		if (birthDate > today) {
			return false;
		} else {
			return true;
		}
	}

	const validateInput = (name, value) => {
		let regex;

		switch (name) {
			case "name":
				regex = /^[a-zA-Z\s]*$/;
				break;
			case "email":
				regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
				break;
			case "phone":
				regex = /^(?:\+88|01)?(?:\d{11}|\d{13})$/;
				break;
			case "username":
				regex = /^[a-zA-Z0-9_-]*$/;
				break;
			case "dob":
				regex = /^\d{4}-\d{2}-\d{2}$/;
				break;
			default:
				return true;
		}

		if (name === "dob" && value) {
			return isEighteenYearsAgo(value);
		}

		return regex.test(value);
	};

	const validateObject = (obj) => {
		const validationResults = {};

		for (const key in obj) {
			validationResults[key] = validateInput(key, obj[key]) ? `` : `${key} not valid value`;
		}

		return validationResults;
	};

	const isActiveFunc = (props) => {
		return props.isActive ? "active " : "";
	};

	const handleOTPOnChange = (e) => {
		const { name, value } = e.target;
		const input = document.getElementsByName(name);

		if (value.length === 1) {
			if (input[0].nextElementSibling) {
				input[0].nextElementSibling.focus();
			}
		} else if (value.length === 0) {
			if (input[0].previousElementSibling) {
				input[0].previousElementSibling.focus();
			}
		}
		setOtp({ ...otp, [name]: value });
	};

	const SIGN_UP = {
		type: "sign-up",
		title: language.signUp.title,
		subTitle: language.signUp.subTitle,
		setSignUp: setSignUp,
		setUserInfo: setUserInfo,
		onSubmit: async (event) => {
			event.preventDefault();
			const dob = moment(signup.dob).format("YYYY-MM-DD").split("T");
			const body = {
				...signup,
				dob: dob[0],
			};
			const validationMessage = validateObject(body);
			const hasErrors = Object.values(validationMessage).some((value) => value !== "");

			if (hasErrors) {
				let errorFields = Object.values(validationMessage);
				errorFields = errorFields.filter((value) => value !== "");
				const renderMessage = (
					<ol style={{ listStyle: "outside" }}>
						{errorFields.map((value, index) => (
							<li key={index} style={{ textTransform: "capitalize", color: "red" }}>
								{value}
							</li>
						))}
					</ol>
				);

				toast.error(renderMessage);
			} else {
				await signUp(body).unwrap();
			}
		},
		fields: [
			// Sign Up
			{
				id: "1",
				type: "text",
				name: "name",
				label: language.signUp.fullname,
				placeholder: "Ex - John Smith",
				className: "py-3",
				required: true,
				onChange: (e) => setSignUp({ ...signup, [e.target.name]: e.target.value }),
				value: signup.name,
			},
			{
				id: "2",
				type: "email",
				name: "email",
				label: language.signUp.email,
				placeholder: "Ex - demo@email.com",
				className: "py-3",
				required: true,
				onChange: (e) => setSignUp({ ...signup, [e.target.name]: e.target.value }),
				value: signup.email,
			},
			{
				id: "3",
				type: "tel",
				name: "phone",
				label: language.signUp.MobileNo,
				placeholder: "Ex - 01*********",
				className: "py-3",
				required: true,
				onChange: (e) => setSignUp({ ...signup, [e.target.name]: e.target.value }),
				value: signup.phone,
			},
			{
				id: "4",
				type: "text",
				name: "username",
				label: language.signUp.userName,
				placeholder: "Ex - john.smith123",
				className: "py-3",
				required: true,
				onChange: (e) => setSignUp({ ...signup, [e.target.name]: e.target.value }),
				value: signup.username,
			},
			{
				id: "5",
				type: "date",
				name: "dob",
				label: language.signUp.DateOfBirth,
				placeholder: "Ex - 10/12/1994",
				className: "py-3",
				required: true,
				onChange: (e) => setSignUp({ ...signup, [e.target.name]: e.target.value }),
				value: signup.dob,
			},

			{
				id: "7",
				type: "trams",
				value: (
					<>
						<div className="mb-3">
							<p className="m-0 p-0" style={{ color: "black" }}>
								{language.PleaseSelectTheOptionToReceiveOTP}:
							</p>
							<Form.Check
								inline
								label={language.signUp.MobileNo}
								name="otp_for"
								type="radio"
								id={`inline-radio-1`}
								checked={signup.otp_for === ""}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "" })}
							/>
							<Form.Check
								inline
								label={language.signUp.email}
								name="otp_for"
								type="radio"
								value={signup.otp_for}
								id={`inline-radio-2`}
								checked={signup.otp_for === "email"}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "email" })}
							/>
						</div>
						<div class="form-check  m-0 p-0">
							<input
								class="form-check-input "
								type="checkbox"
								value=""
								id="flexCheckDefault"
								style={{
									paddingRight: "5px",
									marginRight: "5px",
									display: "block",
								}}
							/>
							<label class="form-check-label  text-left" for="flexCheckDefault">
								{language.signUp.privatePolicy}.&nbsp;
								<span>
									<button
										style={{
											backgroundColor: "transparent",
											borderColor: "transparent",
											color: "blue",
										}}
										className="text-decoration-underline"
										onClick={() => {
											dispatch(isModalOpen(false));
											navigate("/privacy-details/terms-condition");
										}}
									>
										{language.signUp.TermsOfUse}
									</button>{" "}
									&{" "}
									<button
										style={{
											backgroundColor: "transparent",
											borderColor: "transparent",
											color: "blue",
										}}
										className="text-decoration-underline"
										onClick={() => {
											dispatch(isModalOpen(false));
											navigate("/privacy-details/privacy-policy");
										}}
									>
										{language.signUp.PrivacyPolicy}
									</button>
								</span>
							</label>
						</div>
					</>
				),
			},
			{
				id: "8",
				type: "submit",
				value: language.signUp.btn,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingSignUp,
			},
		],
		footer: {
			title: "",
			subTitle: (
				<>
					{language.signUp.alreadyHaveAccount}&nbsp;
					<span
						role="button"
						className="text-decoration-underline"
						onClick={() => dispatch(userModalType("login"))}
					>
						{language.signUp.signIn}
					</span>
				</>
			),
		},
	};

	const OTP = {
		type: "otp",
		title: language.VerifyYourAccount,
		subTitle:
			signup?.otp_for === "email"
				? `${language.AnOTPhaSeenSentToYourEmailAddress} ${String(signup?.email).substring(
						0,
						3,
				  )}*******@${String(signup?.email || phoneNumber).split("@")[1]}. ${
						language.PleaseEnterTheOTPBelow
				  }.`
				: `${language.AnOTPhaSeenSentToYourMobileNumber} ${String(signup?.phone).substring(
						0,
						3,
				  )}*******${String(signup?.phone || phoneNumber).substring(9)}. ${
						language.PleaseEnterTheOTPBelow
				  }.`,
		otp: Object.values(otp).join(""),
		time: 150,
		setOtp: setOtp,
		resetOTPSubmit: async () => {
			// console.log("reset otp submit");
			setOtp({});
			if (isForgetPassword) {
				await forgotPassword({ phone: phoneNumber });
			} else {
				await signUp(signup).unwrap();
			}
		},
		disabled: isLoadingOtpVerification,
		onSubmit: (e) => {
			e.preventDefault();

			const value = Object.values(otp).join("");
			if (Object.values(otp).length === 5 && Object.values(otp).every((itm) => !isNaN(itm))) {
				if (!signup) {
					toast.error("Phone number not found");
				} else {
					if (isForgetPassword) {
						otpVerification({
							phone: forgotPasswordRes?.data?.phone,
							code: Number(value),
						});
					} else {
						otpVerification({
							phone:
								signup?.otp_for === "email"
									? signUpRes?.data?.email
									: signUpRes?.data?.phone,
							code: Number(value),
						});
					}
				}
			} else {
				toast.error("OTP verification failed");
			}
		},

		fields: [
			//OTP
			{
				id: "1",
				type: "text",
				name: "otp1",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp1 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "2",
				type: "text",
				name: "otp2",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp2 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "3",
				type: "text",
				name: "otp3",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp3 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "4",
				type: "text",
				name: "otp4",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp4 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "5",
				type: "text",
				name: "otp5",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp5 || "",
				onChange: handleOTPOnChange,
			},
		],
	};

	const LOGIN = {
		type: "login",
		title: language.login.title,
		subTitle: language.login.subTitle,
		setSignUp: setSignUp,
		setUserInfo: setUserInfo,
		onSubmit: async (e) => {
			e.preventDefault();
			await login(logIn);
		},
		fields: [
			{
				id: "1",
				type: "text",
				name: "username",
				label: language.login.userName,
				placeholder: "Ex - john.smith123",
				className: "py-3",
				required: true,
				onChange: (e) => setLogin({ ...logIn, [e.target.name]: e.target.value }),
			},
			{
				id: "2",
				type: "password",
				name: "password",
				label: language.login.password,
				placeholder: language.ResetYourPassword.enterPassword,
				className: "py-3",
				required: true,
				onChange: (e) => setLogin({ ...logIn, [e.target.name]: e.target.value }),
			},
			{
				id: "3",
				type: "submit",
				value: language.login.btn,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingLogin,
			},
		],
		footer: {
			title: (
				<>
					<span
						role="button"
						onClick={() => {
							dispatch(setIsForgotPassword(true));
							dispatch(userModalType("forget-password"));
						}}
						className="text-decoration-underline"
					>
						{language.login.forgotPassword}
					</span>
				</>
			),
			subTitle: (
				<>
					{language.login.donotHaveAccount}&nbsp;
					<span
						role="button"
						className="text-decoration-underline"
						onClick={() => dispatch(userModalType("sign-up"))}
					>
						{language.login.signUp}
					</span>
				</>
			),
		},
	};

	const FORGET_PASSWORD = {
		type: "forget-password",
		title: language.forgetPassword,
		subTitle: language.ResetYourPassword.subTitle,
		onSubmit: (e) => {
			e.preventDefault();
			forgotPassword({ phone: phoneNumber, otp_for: signup?.otp_for });
		},
		fields: [
			{
				id: "7",
				type: "trams",
				value: (
					<>
						<div className="mb-3">
							<p className="m-0 p-0" style={{ color: "black" }}>
								{language?.PleaseSelectTheOptionToReceiveOTP}
							</p>
							<Form.Check
								inline
								label={language.signUp.MobileNo}
								name="otp_for"
								type="radio"
								id={`inline-radio-1`}
								checked={signup.otp_for === ""}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "" })}
							/>
							<Form.Check
								inline
								label={language.signUp.email}
								name="otp_for"
								type="radio"
								value={signup.otp_for}
								id={`inline-radio-2`}
								checked={signup.otp_for === "email"}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "email" })}
							/>
						</div>
					</>
				),
			},
			{
				id: "11",
				type: signup?.otp_for === "email" ? "email" : "tel",
				name: "phone",
				label:
					signup?.otp_for === "email"
						? language?.EmailAddress
						: language.ResetYourPassword.MobileNo,
				placeholder:
					signup?.otp_for === "email" ? "Ex - demo@gmail.com" : "Ex - 01*********",
				className: "py-3",
				required: true,
				onChange: (e) => setPhoneNumber(e.target.value),
			},
			{
				id: "2",
				type: "submit",
				value: language.ResetYourPassword.btn,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingForgotPassword,
			},
		],
	};

	const LegalProfessional = {
		type: "forget-password",
		title: language.CorporateSignIn,
		subTitle: language.ResetYourPassword.subTitle,
		onSubmit: (e) => {
			e.preventDefault();
			sendOtp({ mobile: parseInt(phoneNumber), otp_for: signup?.otp_for });
		},
		fields: [
			{
				id: "7",
				type: "trams",
				value: (
					<>
						<div className="mb-3">
							<p className="m-0 p-0" style={{ color: "black" }}>
								{language?.PleaseSelectTheOptionToReceiveOTP}
							</p>
							<Form.Check
								inline
								label={language.signUp.MobileNo}
								name="otp_for"
								type="radio"
								id={`inline-radio-1`}
								checked={signup.otp_for === ""}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "" })}
							/>
							<Form.Check
								inline
								label={language.signUp.email}
								name="otp_for"
								type="radio"
								value={signup.otp_for}
								id={`inline-radio-2`}
								checked={signup.otp_for === "email"}
								onChange={(e) => setSignUp({ ...signup, [e.target.name]: "email" })}
							/>
						</div>
					</>
				),
			},
			{
				id: "11",
				type: signup?.otp_for === "email" ? "email" : "tel",
				name: "phone",
				label:
					signup?.otp_for === "email"
						? language?.EmailAddress
						: language.ResetYourPassword.MobileNo,
				placeholder:
					signup?.otp_for === "email" ? "Ex - demo@gmail.com" : "Ex - 01*********",
				className: "py-3",
				required: true,
				onChange: (e) => setPhoneNumber(e.target.value),
			},
			{
				id: "2",
				type: "submit",
				value: language.SendOTP,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingSendOtp,
			},
		],
	};

	const LegalProfessionalOTP = {
		type: "legal-professional-otp",
		title: language.VerifyYourMobileNumber,
		subTitle:
			signup?.otp_for === "email"
				? `${language.AnOTPhaSeenSentToYourEmailAddress} ${String(
						sendOtpRes?.data?.email,
				  ).substring(0, 3)}*******@${String(sendOtpRes?.data?.email).split("@")[1]}. ${
						language.PleaseEnterTheOTPBelow
				  }.`
				: `${language.AnOTPhaSeenSentToYourMobileNumber} ${String(phoneNumber).substring(
						0,
						3,
				  )}*******${String(phoneNumber).substring(9)}. ${
						language.PleaseEnterTheOTPBelow
				  }.`,
		otp: Object.values(otp).join(""),
		time: 150,
		setOtp: setOtp,
		resetOTPSubmit: async () => {
			sendOtp({ mobile: parseInt(phoneNumber) });
		},
		disabled: isLoadingCorporateLogin,
		onSubmit: (e) => {
			e.preventDefault();

			const value = Object.values(otp).join("");

			if (Object.values(otp).length === 5 && Object.values(otp).every((itm) => !isNaN(itm))) {
				corporateLogin({
					mobile: parseInt(phoneNumber),
					otp: parseInt(value),
				});
			} else {
				toast.error("OTP verification failed");
			}
		},

		fields: [
			//OTP
			{
				id: "1",
				type: "text",
				name: "otp1",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp1 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "2",
				type: "text",
				name: "otp2",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp2 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "3",
				type: "text",
				name: "otp3",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp3 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "4",
				type: "text",
				name: "otp4",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp4 || "",
				onChange: handleOTPOnChange,
			},
			{
				id: "5",
				type: "text",
				name: "otp5",
				className: "py-3",
				maxLength: 1,
				max: 1,
				value: otp?.otp5 || "",
				onChange: handleOTPOnChange,
			},
		],
	};

	const CREATE_PASSWORD = {
		type: "reset-password",
		title: language.ResetYourPassword.title,
		onSubmit: (e) => {
			e.preventDefault();

			if (createPassword.password !== createPassword.c_password) {
				toast.error("Passwords do not match");
				return;
			}
			if (!otpVerificationRes?.otp_verify_token) {
				if (isForgetPassword) {
					passwordReset({
						...createPassword,
						phone: forgotPasswordRes?.data?.phone,
						token: otpVerificationRes?.data?.otp_verify_token,
					});
				} else {
					passwordCreate({
						...createPassword,
						...signUpRes.data,
						token: otpVerificationRes?.data?.otp_verify_token,
					});
				}
			} else {
				toast.error("OTP verification failed");
			}
		},
		fields: [
			{
				id: "1",
				type: "password",
				name: "password",
				label: language.ResetYourPassword.newPassword,
				placeholder: language.ResetYourPassword.placeholderNewPassword,
				className: "py-3",
				required: true,
				onChange: (e) =>
					setCreatePassword({ ...createPassword, [e.target.name]: e.target.value }),
				value: createPassword.password,
			},
			{
				id: "2",
				type: "password",
				name: "c_password",
				label: language.ResetYourPassword.confirmPassword,
				placeholder: language.ResetYourPassword.placeholderConfirmPassword,
				className: "py-3",
				required: true,
				onChange: (e) =>
					setCreatePassword({ ...createPassword, [e.target.name]: e.target.value }),
				value: createPassword.c_password,
			},
			{
				id: "3",
				type: "submit",
				value: language.ResetYourPassword.btn,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingPasswordCreate || isLoadingPasswordReset,
			},
		],
	};

	const businessInquiry2 = {
		type: "business-inquiry",
		title: language?.BusinessInquiry,
		subTitle: language.signUp.subTitle,
		setBusinessInquiry: setBusinessInquiry,
		setUserInfo: setUserInfo,
		onSubmit: async (event) => {
			event.preventDefault();

			await businessInquiryPost({
				name: businessInquiry.fullname,
				email: businessInquiry.email,
				details: businessInquiry.inquiry_details,
			}).unwrap();
		},
		fields: [
			{
				id: "2",
				type: "email",
				name: "email",
				label: language.signUp.email,
				placeholder: "Ex - demo@email.com",
				className: "py-3",
				required: true,
				onChange: (e) =>
					setBusinessInquiry({ ...businessInquiry, [e.target.name]: e.target.value }),
				value: businessInquiry.email,
			},

			{
				id: "4",
				type: "text",
				name: "fullname",
				label: language.fullname,
				placeholder: "Ex - john.smith123",
				className: "py-3",
				required: true,
				onChange: (e) =>
					setBusinessInquiry({ ...businessInquiry, [e.target.name]: e.target.value }),
				value: businessInquiry.fullname,
			},
			{
				id: "4",
				type: "text",
				name: "inquiry_details",
				label: language.InquiryDetails,
				placeholder: "Ex - inquiry details",
				className: "py-3",
				required: true,
				onChange: (e) =>
					setBusinessInquiry({ ...businessInquiry, [e.target.name]: e.target.value }),
				value: businessInquiry.inquiry_details,
			},

			{
				id: "7",
				type: "submit",
				value: language.SendInquiry,
				className: "rn-btn edu-btn w-100 d-block",
				disabled: isLoadingBusinessInquiry,
			},
		],
	};

	const authRender = {
		"sign-up": SIGN_UP,
		otp: OTP,
		login: LOGIN,
		"forget-password": FORGET_PASSWORD,
		"create-password": CREATE_PASSWORD,
		"business-inquiry": businessInquiry2,
		"legal-professional": LegalProfessional,
		"legal-professional-otp": LegalProfessionalOTP,
	};

	useEffect(() => {
		if (modal === false) {
			// reset all state
			setBusinessInquiry({
				fullname: "",
				email: "",
				inquiry_details: "",
			});
			setSignUp({
				name: "",
				email: "",
				phone: "",
				username: "",
				dob: "",
				otp_for: "",
			});
			setLogin({
				username: "",
				password: "",
			});
			setPhoneNumber("");
			setCreatePassword({
				password: "",
				c_password: "",
			});
			setOtp({});
		}
	}, [modal]);

	return (
		<nav>
			<header
				className={`edu-header header-transparent header-style-2 header-default ${classes} ${stickyStatus}`}
				style={
					classes !== "sticky" && user
						? { position: "relative", height: "auto" }
						: { height: "auto" }
				}
			>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-3 col-xl-2 col-md-6  col-6">
							<div className="">
								<Link
									to={navBar.logo.link}
									style={{
										width: navBar.logo.width,
										height: navBar.logo.height,
									}}
								>
									<img
										className="logo-light"
										alt={navBar.logo.alt}
										src={site_logo}
										width={navBar.logo.width}
										height={navBar.logo.height}
										style={{ width: "none" }}
										loading="lazy"
									/>
								</Link>
							</div>
						</div>

						<div className="col-lg-9 col-xl-10 col-md-6 col-6">
							<div className="header-right d-flex justify-content-end align-items-center">
								<div className="d-none d-xl-block">
									<nav className="mainmenu-nav d-none d-lg-block">
										<ul className="mainmenu">
											{navBar.nav.map((path, index) => {
												if (path.type === "dropdown") {
													return (
														<Dropdown
															className="d-flex align-items-center fs-5"
															key={index}
														>
															<Dropdown.Toggle
																as={CustomToggle}
																id="dropdown-custom-components"
																className="fs-5"
															>
																{path?.text}
															</Dropdown.Toggle>

															<Dropdown.Menu>
																{path.options?.map((option, i) => (
																	<Dropdown.Item
																		eventKey={option?.id}
																		className="fs-5"
																		onClick={option?.onClick}
																	>
																		{option.children}
																	</Dropdown.Item>
																))}
															</Dropdown.Menu>
														</Dropdown>
													);
												}
												return (
													<li key={index}>
														<NavLink
															to={path.link}
															className={isActiveFunc}
															rel={path.rel}
															target={path.target}
														>
															{path.text}
														</NavLink>
													</li>
												);
											})}
										</ul>
									</nav>
								</div>

								{!navBar.isLogin && (
									<>
										<div className="header-menu-bar p-0 m-0">
											<div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
												<button
													className="edu-btn btn-medium header-button edu-btn-outline"
													onClick={navBar.others[0].onClick}
													type={navBar.others[0].type}
												>
													{navBar.others[0].text}
												</button>
											</div>
										</div>
										<div className="header-menu-bar">
											<div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
												<button
													className="edu-btn btn-medium header-button"
													onClick={navBar.others[1].onClick}
													type={navBar.others[1].type}
												>
													{navBar.others[1].text}
												</button>
											</div>
										</div>
									</>
								)}

								{/* {!notShow && (
									
								)} */}
								<div className="header-menu-bar">
									<div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
										<select
											className=" form-select form-select-lg border-0 lan-select"
											aria-label=".form-select-lg example"
											onChange={navBar.others[2].onChange}
											value={navBar.others[2].value}
											style={{
												fontWeight: 600,
												fontSize: "16px",
											}}
										>
											{navBar.others[2].options?.map((option) => (
												<option
													key={option.id}
													className="lan-option px-2"
													value={option.value}
													style={{
														fontWeight: 600,
														fontSize: "16px",
													}}
												>
													{option.text}
												</option>
											))}
										</select>
									</div>
								</div>

								{navBar.isLogin && (
									<>
										<div className="header-menu-bar ">
											<div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
												<Dropdown as={ButtonGroup}>
													<Dropdown.Toggle
														split
														variant="success"
														id="dropdown-split-basic"
														style={{
															backgroundColor: "transparent",
															border: "none",
															color: "white",
														}}
													>
														<Button
															variant="success"
															style={{
																backgroundColor: "transparent",
																border: "none",
																color: "white",
															}}
														>
															<p
																className="m-0"
																style={{ position: "relative" }}
															>
																{navBar.others[3].name}
																<Badge
																	bg="danger"
																	style={{
																		position: "absolute",
																		top: -10,
																		right: -15,
																		borderRadius: "50%",
																		border: "2px solid white",
																	}}
																>
																	{
																		navBar.others[3]?.options
																			?.length
																	}
																</Badge>
															</p>
														</Button>
													</Dropdown.Toggle>

													<Dropdown.Menu
														style={{
															width: "25vw",
															padding: "20px",
															borderRadius: "10px",
														}}
													>
														<div
															style={{
																fontWeight: 600,
																fontSize: "20px",
																paddingBottom: "10px",
															}}
														>
															{language.Notifications}
														</div>
														{navBar.others[3]?.options?.length > 0 ? (
															navBar.others[3]?.options?.map(
																({ children, ...option }, i) => {
																	return (
																		<Dropdown.Item
																			key={i}
																			{...option}
																			style={{
																				fontWeight: 600,
																				fontSize: "16px",
																				whiteSpace:
																					"inherit",
																				padding: "10px 0px",
																				borderTop:
																					"1px solid #e5e5e5",
																			}}
																		>
																			<div className="d-flex">
																				<div className="me-4">
																					{option?.is_read ? (
																						<Read
																							style={{
																								width: 40,
																								height: 40,
																							}}
																						/>
																					) : (
																						<Unread
																							style={{
																								width: 40,
																								height: 40,
																							}}
																						/>
																					)}
																				</div>

																				<div>
																					<p
																						className="m-0 p-0"
																						style={{
																							wordWrap:
																								"break-word",
																							color: "black",
																						}}
																					>
																						{children}
																					</p>
																					<p
																						className="m-0 p-0"
																						style={{
																							wordWrap:
																								"break-word",
																						}}
																					>
																						{
																							option.created_at
																						}
																					</p>
																				</div>
																			</div>
																		</Dropdown.Item>
																	);
																},
															)
														) : (
															<h6
																className="text-center"
																style={{ color: "gray" }}
															>
																{language.NoNotificationsFound}
															</h6>
														)}
														{navBar.others[3]?.options?.length > 0 ? (
															<div className="text-center">
																<CustomButton
																	disabled={
																		isLoadingAllNotificationsAsRead
																	}
																	onClick={() =>
																		markAllNotificationsAsRead({
																			id: "all",
																		})
																	}
																	className=""
																	style={{
																		width: "100%",
																		background: "#525fe1",
																		padding: "15px",
																		borderRadius: "5px",
																	}}
																	btnTextStyle={{
																		fontSize: "16px",
																		color: false
																			? "gray"
																			: "white",
																		fontWeight: 600,
																	}}
																>
																	Mark as read
																</CustomButton>
															</div>
														) : null}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</div>
										<div className="header-menu-bar ">
											<div className="quote-icon quote-user d-none d-xl-block ml--15 ml_sm--5">
												<Dropdown
													as={ButtonGroup}
													onSelect={navBar.others[4].onSelect}
												>
													<Dropdown.Toggle
														split
														variant="success"
														id="dropdown-split-basic"
														style={{
															backgroundColor: "transparent",
															border: "none",
															color: "white",
														}}
													>
														<Button
															variant="success"
															style={{
																backgroundColor: "transparent",
																border: "none",
																color: "white",
															}}
														>
															{navBar.others[4].name}
														</Button>
													</Dropdown.Toggle>

													<Dropdown.Menu
														style={{
															minWidth: "100%",
															borderRadius: 10,
															padding: "10px",
														}}
													>
														{navBar.others[4]?.options?.map(
															({ children, ...option }, i, self) => {
																const isLast =
																	self.length - 1 === i;
																return (
																	<Dropdown.Item
																		key={i}
																		{...option}
																		style={{
																			fontWeight: 600,
																			fontSize: "16px",
																			padding: "5px 15px",
																			color: isLast
																				? "red"
																				: "black",
																		}}
																	>
																		{children}
																		{isLast ? (
																			<BsArrowRight
																				style={{
																					marginLeft:
																						"40%",
																				}}
																			/>
																		) : null}
																	</Dropdown.Item>
																);
															},
														)}
													</Dropdown.Menu>
												</Dropdown>
											</div>
										</div>
									</>
								)}
							</div>
							<Col
								sm={12}
								className="mobile-menu-bar justify-content-end w-100 align-items-center d-flex d-xl-none"
							>
								<Col sm={3} md={2} lg={1}>
									<button
										onClick={handleShow}
										className="white-box-icon hamberger-button header-menu"
									>
										<i className="ri-menu-line"></i>
									</button>
								</Col>
							</Col>
						</div>
					</div>
					<Offcanvas
						show={show}
						onHide={handleClose}
						backdrop={true}
						keyboard={true}
						placement="top"
						scroll={false}
						style={{ height: "35vh" }}
						className="mobile-menu"
					>
						<MobileNav
							navBar={navBar}
							isActiveFunc={isActiveFunc}
							isLoadingAllNotificationsAsRead={isLoadingAllNotificationsAsRead}
							markAllNotificationsAsRead={markAllNotificationsAsRead}
							user={user}
						/>
					</Offcanvas>
					{modalType && (
						<ModalAuth
							show={modal}
							onHide={() => {
								dispatch(isModalOpen(false));
							}}
							modal={authRender[modalType]}
							modalType={modalType}
						/>
					)}
				</div>
			</header>

			<ToastContainer position="top-right" newestOnTop />
		</nav>
	);
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<a
		href=""
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
		style={{
			fontWeight: 600,
		}}
	>
		{children}
		<RiArrowDownSLine
			style={{
				fontSize: "20px",
				marginLeft: "2px",
			}}
		/>
	</a>
));
