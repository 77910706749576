import {
	barAssociationListEndpoint,
	chapterLessionContentEndpoint,
	chapterWiseFlashCardEndpoint,
	chapterWiseHighCourtQuestions,
	chapterWiseQuestionEndpoint,
	chapterWiseResultEndpoint,
	checkCouponEndpoint,
	corporateCertificateEndpoint,
	corporateChapterDetailsEndpoint,
	corporateCourseDetailsEndpoint,
	corporateMarkAsReadEndpoint,
	courseDetailsEndpoint,
	courseFeedbackEndpoint,
	courseReviewAllEndpoint,
	courseWiseCertificateEndpoint,
	courseWiseModelTestEndpoint,
	courseWiseResultEndpoint,
	courseWiseWrittenResultEndpoint,
	courseWishList,
	courseWishRemove,
	courseWishStore,
	discussionReplySubmitEndpoint,
	discussionSubmitEndpoint,
	examStageListEndpoint,
	examSubmitEndpoint,
	generateCertificateEndpoint,
	generateCorporateCertificateEndpoint,
	highCourtQuestionDetails,
	markAsReadEndpoint,
	modelTestWiseQuestionEndpoint,
	myCourseDetailsEndpoint,
	paySslEndpoint,
	subjectDetailsEndpoint,
	subjectWrittenContentDetailsEndpoint,
	videoLinkEndpoint,
	webCourseEnrollmentEndpoint,
	webGlobalCourseSearchEndpoint,
	writtenExamSubmitEndpoint,
} from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";

export const courseApi = mainServiceApi
	.enhanceEndpoints({ addTagTypes: ["course"] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getAllCourses: builder.query({
				query: (name) => ({
					url: `${webGlobalCourseSearchEndpoint}${name}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			getCourseDetails: builder.query({
				query: ({ course_id, user_id }) => ({
					url: `${courseDetailsEndpoint}${course_id}${
						user_id ? `&user_id=${user_id}` : ""
					}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),
			courseWishRemove: builder.query({
				query: ({ course_id }) => ({
					url: `${courseWishRemove}?course_id=${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			courseWishList: builder.query({
				query: () => ({
					url: courseWishList,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),

			hasCouponApi: builder.mutation({
				query: (data) => ({
					url: `${checkCouponEndpoint}`,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseWishStore: builder.mutation({
				query: (data) => ({
					url: `${courseWishStore}`,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			getMyCourseDetails: builder.query({
				query: ({ course_id, course_type }) => {
					let endpoint = "";
					if (course_type === "Corporate" || course_type === "Professional") {
						endpoint = corporateCourseDetailsEndpoint;
					} else {
						endpoint = myCourseDetailsEndpoint;
					}
					return {
						url: `${endpoint}?course_id=${course_id}`,
						method: "GET",
						responseHandler: async (response) => {
							return await response.json();
						},
					};
				},
				transformResponse: (response, args, meta) => response,
			}),
			getCourseWiseModelTest: builder.query({
				query: ({ course_id }) => ({
					url: `${courseWiseModelTestEndpoint}?course_id=${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getModelTestWiseQuestion: builder.query({
				query: ({ exam_id, type }) => ({
					url:
						type === "chapter_wise_question"
							? `${chapterWiseQuestionEndpoint}?exam_id=${exam_id}`
							: `${modelTestWiseQuestionEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getResult: builder.query({
				query: ({ exam_id, chapter_id }) => ({
					url: chapter_id
						? `${chapterWiseResultEndpoint}?chapter_id=${chapter_id}`
						: `${courseWiseResultEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			getSubjectDetails: builder.query({
				query: ({ subject_id, chapter_type, course_type, course_id }) => {
					let endpoint = "";
					if (course_type === "Corporate" || course_type === "Professional") {
						endpoint = `${corporateChapterDetailsEndpoint}?course_id=${course_id}`;
					} else if (chapter_type === "MCQ") {
						endpoint = `${subjectDetailsEndpoint}?subject_id=${subject_id}&chapter_type=${chapter_type}`;
					} else {
						endpoint = `${subjectWrittenContentDetailsEndpoint}?subject_id=${subject_id}`;
					}
					return {
						url: endpoint,
						method: "GET",
						responseHandler: async (response) => {
							return await response.json();
						},
					};
				},
				transformResponse: (response, args, meta) => {
					// console.log({ meta, args });
					if (response.code === 200) {
						if (
							meta?.course_type === "Corporate" ||
							meta?.course_type === "Professional"
						) {
							return response.data.chapters;
						} else if (meta?.chapter_type === "MCQ") {
							return response.data.subject_chapter;
						} else {
							return response.data.written_content;
						}
					} else {
						return [];
					}
				},
			}),
			chapterLessonContent: builder.query({
				query: ({ lession_id, lession_type }) => ({
					url: `${chapterLessionContentEndpoint}?lession_id=${lession_id}&lession_type=${lession_type}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseReview: builder.query({
				query: ({ course_id }) => ({
					url: `${courseReviewAllEndpoint}${course_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),
			chapterWiseFlashCard: builder.query({
				query: ({ chapter_id }) => ({
					url: `${chapterWiseFlashCardEndpoint}?chapter_id=${chapter_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response.data,
			}),
			getBarAssociationList: builder.query({
				query: () => ({
					url: barAssociationListEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) =>
					response.data.map((item) => ({
						label: item.name,
						value: item.id,
					})),
			}),
			getExamStageList: builder.query({
				query: () => ({
					url: examStageListEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) =>
					response.data.map((item) => ({
						label: item.name,
						value: item.id,
					})),
			}),
			enrollCourse: builder.mutation({
				query: (data) => ({
					url: webCourseEnrollmentEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			paySsl: builder.mutation({
				query: (data) => ({
					url: paySslEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			discussionSubmit: builder.mutation({
				query: (data) => ({
					url: discussionSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			discussionReplySubmit: builder.mutation({
				query: (data) => ({
					url: discussionReplySubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseFeedback: builder.mutation({
				query: (data) => ({
					url: courseFeedbackEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			examSubmit: builder.mutation({
				query: (data) => ({
					url: examSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			writtenExamSubmit: builder.mutation({
				query: (data) => ({
					url: writtenExamSubmitEndpoint,
					method: "POST",
					body: data,
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
			courseWiseWrittenResult: builder.query({
				query: ({ exam_id }) => ({
					url: `${courseWiseWrittenResultEndpoint}?exam_id=${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			chapterWiseHighCourtQuestions: builder.query({
				query: ({ chapter_id, page }) => ({
					url: `${chapterWiseHighCourtQuestions}?chapter_id=${chapter_id}&page=${page}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			highCourtQuestionDetails: builder.query({
				query: ({ exam_id }) => ({
					url: `${highCourtQuestionDetails}/${exam_id}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			markAsRead: builder.mutation({
				query: ({ lesson_id, course_type }) => ({
					url:
						course_type === "Corporate" || course_type === "Professional"
							? `${corporateMarkAsReadEndpoint}/${lesson_id}`
							: `${markAsReadEndpoint}/${lesson_id}`,
					method: "POST",
					body: { lesson_id },
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				invalidatesTags: (result, error, arg) => [{ type: "dashboard", id: "LIST" }],
			}),
			courseWiseCertificate: builder.query({
				query: (type) => ({
					url:
						type === "corporate"
							? corporateCertificateEndpoint
							: courseWiseCertificateEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			videoLink: builder.query({
				query: ({ videoLinks }) => ({
					url: `${videoLinkEndpoint}/${videoLinks}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
				transformResponse: (response, args, meta) => response,
			}),
			generateCertificate: builder.mutation({
				query: ({ course_id, type }) => ({
					url:
						type === "corporate"
							? generateCorporateCertificateEndpoint
							: generateCertificateEndpoint,
					method: "POST",
					body: { course_id },
					responseHandler: async (response) => {
						return await response.json();
					},
				}),
			}),
		}),
	});

export const {
	useGetCourseDetailsQuery,
	useGetMyCourseDetailsQuery,
	useGetBarAssociationListQuery,
	useGetExamStageListQuery,
	useEnrollCourseMutation,
	usePaySslMutation,
	useDiscussionReplySubmitMutation,
	useDiscussionSubmitMutation,
	useCourseFeedbackMutation,
	useGetAllCoursesQuery,
	useCourseReviewQuery,
	useGetSubjectDetailsQuery,
	useChapterLessonContentQuery,
	useGetCourseWiseModelTestQuery,
	useGetModelTestWiseQuestionQuery,
	useExamSubmitMutation,
	useChapterWiseFlashCardQuery,
	useMarkAsReadMutation,
	useGetResultQuery,
	useWrittenExamSubmitMutation,
	useCourseWiseWrittenResultQuery,
	useChapterWiseHighCourtQuestionsQuery,
	useHighCourtQuestionDetailsQuery,
	useHasCouponApiMutation,
	useCourseWiseCertificateQuery,
	useGenerateCertificateMutation,
	useVideoLinkQuery,
	useLazyVideoLinkQuery,
	useCourseWishStoreMutation,
	useLazyCourseWishRemoveQuery,
	useCourseWishListQuery,
} = courseApi;
