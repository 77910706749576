import React from "react";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { lanChange } from "src/utils";
import bgImage from "../../assets/images/bread-img.png";
const BreadcrumbOne = ({ title, rootUrl, parentUrl, currentUrl, meddle = null, link = null }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { subject_id, chapter_id, course_id } = useParams();
	const path = window.location.pathname;
	const course_type = searchParams.get("course_type");
	const user = useSelector((state) => state.auth.user);
	const language = lanChange();
	return (
		<div
			className={` breadcrumb-style-1  bg-color bg-image edu-breadcrumb-area`}
			style={
				user
					? {
							backgroundImage: `url(${bgImage}) `,
							paddingTop: "20px",
							paddingBottom: "20px",
					  }
					: {
							paddingTop: "130px",
							paddingBottom: "60px",
							backgroundImage: `url(${bgImage}) `,
					  }
			}
		>
			<div className="container eduvibe-animated-shape">
				<div className="row">
					<div className="col-lg-12">
						<div className="breadcrumb-inner text-start">
							<div className="page-title p-0" style={{}}>
								<h3
									className="p-0 m-0"
									style={{
										fontSize: "22px",
										fontFamily: "Onest",
									}}
									dangerouslySetInnerHTML={{ __html: currentUrl }}
								></h3>
							</div>
							<div className="row justify-content-between align-items-center">
								<div className="col-7">
									<nav
										className={`edu-breadcrumb-nav `}
										style={user ? { padding: "0px 0px" } : {}}
									>
										<ol className="edu-breadcrumb d-flex justify-content-start liststyle">
											<li className="breadcrumb-item">
												<Link
													to={`${rootUrl}`}
													dangerouslySetInnerHTML={{ __html: parentUrl }}
												></Link>
											</li>
											{currentUrl && (
												<IoChevronForwardOutline
													style={{
														color: "gray",
														fontSize: 14,
														fontWeight: 700,
														margin: "0px 5px",
													}}
												/>
											)}

											{meddle &&
												meddle.map((item, index) => {
													return (
														<React.Fragment key={index}>
															<li className="breadcrumb-item">
																<Link
																	to={item.url}
																	dangerouslySetInnerHTML={{
																		__html: item.name,
																	}}
																></Link>
															</li>

															<IoChevronForwardOutline
																style={{
																	color: "gray",
																	fontSize: 14,
																	fontWeight: 700,
																	margin: "0px 5px",
																}}
															/>
														</React.Fragment>
													);
												})}
											{currentUrl && (
												<li
													className="breadcrumb-item active"
													dangerouslySetInnerHTML={{ __html: currentUrl }}
												></li>
											)}
										</ol>
									</nav>
								</div>
								<div
									className="text-end col-5"
									role="button"
									onClick={() => {
										if (
											course_type === "Professional" ||
											course_type === "Corporate"
										) {
											if (`/my-course/${course_id}` === path) {
												if (course_type === "Professional") {
													navigate(
														`/course/Corporate-Compliance/legal-professional-course-details/${course_id}`,
													);
												} else {
													navigate(`/student-dashboard`);
												}
											} else {
												navigate(
													`/my-course/${course_id}?course_type=${course_type}`,
												);
											}
										} else {
											if (link) {
												navigate(link);
											} else {
												navigate(-1);
											}
										}
									}}
									style={{
										cursor: "pointer",
									}}
								>
									<IoChevronBackOutline
										style={{
											color: "gray",
											fontSize: 14,
											fontWeight: 700,
											margin: "0px 5px 0px 0px",
										}}
									/>
									<span>{language.subjectAndChapter.back}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BreadcrumbOne;
