import {
	corporateDashboardEndpoint,
	corporateLoginEndpoint,
	forgotPasswordEndpoint,
	loginEndpoint,
	logoutEndpoint,
	notificationEndpoint,
	otpVerificationEndpoint,
	passwordCreateEndpoint,
	passwordResetEndpoint,
	registerEndpoint,
	sendOtpEndpoint,
	socialLoginEndpoint,
	userEndpoint,
} from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";

export const authApi = mainServiceApi.enhanceEndpoints({ addTagTypes: ["auth"] }).injectEndpoints({
	endpoints: (builder) => ({
		signUp: builder.mutation({
			query: (data) => {
				return {
					url: registerEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		login: builder.mutation({
			query: (data) => {
				return {
					url: loginEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},

			invalidateTags: [{ type: "course", id: "LIST" }],
		}),
		otpVerification: builder.mutation({
			query: (data) => {
				return {
					url: otpVerificationEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		forgotPassword: builder.mutation({
			query: (data) => {
				return {
					url: forgotPasswordEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		passwordReset: builder.mutation({
			query: (data) => {
				return {
					url: passwordResetEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		passwordCreate: builder.mutation({
			query: (data) => {
				return {
					url: passwordCreateEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		getUser: builder.query({
			query: () => {
				return {
					url: userEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		getNotification: builder.query({
			query: () => {
				return {
					url: notificationEndpoint,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),

		getSocialLogin: builder.mutation({
			query: (data) => {
				return {
					url: `${socialLoginEndpoint}`,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		logout: builder.mutation({
			query: () => {
				return {
					url: logoutEndpoint,
					method: "POST",
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		businessInquiryPost: builder.mutation({
			query: (data) => {
				return {
					url: "api/business-inquery",
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		sendOtp: builder.mutation({
			query: (data) => {
				return {
					url: sendOtpEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		corporateLogin: builder.mutation({
			query: (data) => {
				return {
					url: corporateLoginEndpoint,
					method: "POST",
					body: data,
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
		corporateDashboard: builder.query({
			query: (data) => {
				return {
					url: corporateDashboardEndpoint,
					method: "GET",
				};
			},
			transformResponse: (response) => {
				return response;
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useSignUpMutation,
	useGetUserMutation,
	useGetNotificationQuery,
	useForgotPasswordMutation,
	useOtpVerificationMutation,
	usePasswordCreateMutation,
	useLogoutMutation,
	usePasswordResetMutation,
	useGetSocialLoginMutation,
	useBusinessInquiryPostMutation,
	useCorporateDashboardQuery,
	useCorporateLoginMutation,
	useSendOtpMutation,
} = authApi;
