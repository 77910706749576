import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	course: null,
	courseDetails: null,
	allCoursesLegalProfessional: [],
	allCourses: [],
	myCourses: null,
	courseWiseExams: null,
	subject: null,
	chapter: null,
	questions: null,
	questionView: null,
	chapterWiseFlashCard: null,
	filter: {
		minPrice: 0,
		maxPrice: 50000,
		courses: [],
		coursesFilter: [],
		categoriesFilter: [],
		rating: [
			{
				value: 5,
				id: Date.now() * 5,
				label: "5 Star",
				checked: false,
			},
			{
				value: 4,
				id: Date.now() + 4,
				label: "4 Star & up",
				checked: false,
			},
			{
				value: 3,
				id: Date.now() + 3,
				label: "3 Star & up",
				checked: false,
			},
			{
				value: 2,
				id: Date.now() + 2,
				label: "2 Star & up",
				checked: false,
			},
			{
				value: 1,
				id: Date.now() + 1,
				label: "1 Star & up",
				checked: false,
			},
		],
	},
	filterLegalProfessional: {
		courses: [],
		coursesFilter: [],
		rating: [
			{
				value: 5,
				id: Date.now() * 5,
				label: "5 Star",
				checked: false,
			},
			{
				value: 4,
				id: Date.now() + 4,
				label: "4 Star & up",
				checked: false,
			},
			{
				value: 3,
				id: Date.now() + 3,
				label: "3 Star & up",
				checked: false,
			},
			{
				value: 2,
				id: Date.now() + 2,
				label: "2 Star & up",
				checked: false,
			},
			{
				value: 1,
				id: Date.now() + 1,
				label: "1 Star & up",
				checked: false,
			},
		],
	},
};

const courseSlice = createSlice({
	name: "course",
	initialState,
	reducers: {
		setCourse: (state, action) => {
			state.course = action.payload;
		},
		setAllCourses: (state, action) => {
			state.allCourses = action.payload;
		},
		setAllCoursesLegalProfessional: (state, action) => {
			state.allCoursesLegalProfessional = action.payload;
		},
		setCourseFilter: (state, action) => {
			state.filter = action.payload;
		},
		setCourseDetailsLegalProfessional: (state, action) => {
			state.courseDetails = action.payload;
		},
		setCourseFilterLegalProfessional: (state, action) => {
			state.filterLegalProfessional = action.payload;
		},
		setMyCourses: (state, action) => {
			state.myCourses = action.payload;
		},
		setSubject: (state, action) => {
			state.subject = action.payload;
		},
		setChapter: (state, action) => {
			state.chapter = action.payload;
		},
		setCourseWiseExams: (state, action) => {
			state.courseWiseExams = action.payload;
		},
		setQuestions: (state, action) => {
			state.questions = action.payload;
		},
		setChapterWiseFlashCard: (state, action) => {
			state.chapterWiseFlashCard = action.payload;
		},
		setQuestionView: (state, action) => {
			state.questionView = action.payload;
		},
	},
});

export const {
	setCourse,
	setAllCourses,
	setCourseFilter,
	setMyCourses,
	setSubject,
	setChapter,
	setCourseWiseExams,
	setQuestions,
	setChapterWiseFlashCard,
	setQuestionView,
	setAllCoursesLegalProfessional,
	setCourseFilterLegalProfessional,
	setCourseDetailsLegalProfessional,
} = courseSlice.actions;
export default courseSlice.reducer;
