import { dashboardEndpoint } from "../../endpoints";
import mainServiceApi from "../../endpoints/mainServiceApi";

export const dashboardApi = mainServiceApi
	.enhanceEndpoints({ addTagTypes: ["dashboard"] })
	.injectEndpoints({
		endpoints: (builder) => ({
			getDashboard: builder.query({
				query: (user_id) => ({
					url: `${dashboardEndpoint}${user_id ? `?user_id=${user_id}` : ""}`,
					method: "GET",
					responseHandler: async (response) => {
						return await response.json();
					},
				}),

				providesTags: (result) => {
					return [{ type: "dashboard", id: "LIST" }];
				},

				// ? Transform the result to prevent nested data
				transformResponse: (response) => {
					return response;
				},
			}),
		}),
	});

export const { useGetDashboardQuery } = dashboardApi;
