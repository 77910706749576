import { useEffect, useRef } from "react";
import { Card, Col, Row } from "react-bootstrap";
import {
	BsFillPlayCircleFill,
	BsFillSkipEndFill,
	BsFillSkipStartFill,
	BsFillStopCircleFill,
	BsFillVolumeUpFill,
	BsXLg,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { setAudio } from "src/redux/audio/audioSlice";

import { truncateString } from "src/utils";

export default function AudioPlayer() {
	const audio = useSelector((state) => state.audio.audio);

	const audioRef = useRef();
	const dispatch = useDispatch();

	const onClick = () => {
		dispatch(
			setAudio({
				...audio,
				show: false,
				src: null,
				title: "",
				playing: false,
				currentProgress: 0,
			}),
		);
	};

	const togglePlayPause = () => {
		if (audio.playing) {
			audioRef.current?.pause();
			dispatch(setAudio({ ...audio, playing: false }));
		} else {
			audioRef.current?.play();
			dispatch(setAudio({ ...audio, playing: true }));
		}
	};

	const handleVolumeChange = (e) => {
		audioRef.current.volume = e.target.value;
		dispatch(setAudio({ ...audio, volume: e.target.value }));
	};

	const handleBufferProgress = (e) => {
		const audio = e.currentTarget;
		const dur = audio.duration;
		if (dur > 0) {
			for (let i = 0; i < audio.buffered.length; i++) {
				if (audio.buffered.start(audio.buffered.length - 1 - i) < audio.currentTime) {
					const bufferedLength = audio.buffered.end(audio.buffered.length - 1 - i);
					// dispatch(setAudio({ ...audio, buffered: bufferedLength }));
					break;
				}
			}
		}
	};
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
	};
	function forwardAudio() {
		// Check if audioRef is defined and if the audio's current time + 10s is less than its duration
		if (audioRef.current && audioRef.current?.currentTime + 10 <= audioRef.current?.duration) {
			// Forward the audio by 10s
			audioRef.current.currentTime += 10;
		}
	}

	function backwardAudio() {
		// Check if audioRef is defined and if the audio's current time - 10s is greater than 0
		if (audioRef.current && audioRef.current.currentTime - 10 >= 0) {
			// Backward the audio by 10s
			audioRef.current.currentTime -= 10;
		}
	}

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current?.play();
			dispatch(setAudio({ ...audio, playing: true }));
		}
	}, [audio.show]);

	return (
		<div>
			<div
				className={`myElement width-30 ${!audio.show ? "slideOut" : ""}`}
				style={stylesForCardShow}
			>
				{audio.show ? (
					<Card style={stylesForCard}>
						<Card.Body className="py-4">
							<Card.Title className="p-4">
								<div className="d-flex justify-content-between">
									<div
										dangerouslySetInnerHTML={{
											__html: truncateString(audio?.title, 30),
										}}
									></div>
									<div className="btn  fs-4 fw-bold" onClick={onClick}>
										<BsXLg style={{ fontSize: 18, fontWeight: 900 }} />
									</div>
								</div>
							</Card.Title>
							{/* audio player design also add all functionality */}
							<div>
								<div className="px-4">
									<input
										type="range"
										min="0.00"
										step="0.01"
										max={parseInt(audio.duration) || 0}
										value={audio.currentProgress}
										onChange={(e) => {
											if (!audioRef.current) return;
											audioRef.current.currentTime =
												e.currentTarget.valueAsNumber;
											dispatch(
												setAudio({
													...audio,
													currentProgress: e.currentTarget.valueAsNumber,
												}),
											);
										}}
										style={{
											padding: "0px 0px",
											margin: "0px 0px",
											cursor: "pointer",
											color: "rgb(60, 93, 173)",
										}}
									/>
								</div>

								<div
									className="px-4"
									style={{
										color: "black",
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<div>{formatTime(audio.duration - audio.currentProgress)}</div>
									<div>{formatTime(audio.duration)}</div>
								</div>

								<Row className="align-items-center justify-content-between px-3">
									<Col sm={6} className="d-flex align-items-center m-0 p-0">
										<Col sm={2}>
											<button
												onClick={forwardAudio}
												style={{
													background: "none",
													border: "none",
												}}
											>
												<BsFillSkipStartFill
													role="button"
													style={{
														fontSize: 32,
														color: "rgb(60, 93, 173)",
													}}
												/>
											</button>
										</Col>
										<Col sm={2}>
											<button
												onClick={togglePlayPause}
												style={{
													background: "none",
													border: "none",
												}}
											>
												{audio.playing ? (
													<BsFillStopCircleFill
														role="button"
														style={{
															fontSize: 32,
															color: "rgb(60, 93, 173)",
														}}
													/>
												) : (
													<BsFillPlayCircleFill
														role="button"
														style={{
															fontSize: 32,
															color: "rgb(60, 93, 173)",
														}}
													/>
												)}
											</button>
										</Col>
										<Col sm={2}>
											<button
												onClick={backwardAudio}
												style={{
													background: "none",
													border: "none",
												}}
											>
												<BsFillSkipEndFill
													role="button"
													style={{
														fontSize: 32,
														color: "rgb(60, 93, 173)",
													}}
												/>
											</button>
										</Col>
									</Col>
									<Col sm={3} md={3} lg={4} className="d-flex align-items-center">
										<button
											// onClick={togglePlayPause}
											style={{
												background: "none",
												border: "none",
											}}
										>
											<BsFillVolumeUpFill
												role="button"
												style={{
													fontSize: 32,
													color: "rgb(60, 93, 173)",
												}}
											/>
										</button>
										<input
											type="range"
											min="0.00"
											max="1"
											step="0.01"
											value={audio.volume}
											onChange={handleVolumeChange}
											style={{ padding: 0, margin: 0 }}
										/>
									</Col>
								</Row>
							</div>
							{audio.src ? (
								<audio
									preload="metadata"
									ref={audioRef}
									onDurationChange={(e) =>
										dispatch(
											setAudio({
												...audio,
												duration: e.currentTarget.duration,
											}),
										)
									}
									onCanPlay={(e) => {
										e.currentTarget.volume = audio?.volume || 0.5;
										dispatch(setAudio({ ...audio, isReady: true }));
									}}
									onPlaying={() =>
										dispatch(setAudio({ ...audio, playing: true }))
									}
									onPause={() => dispatch(setAudio({ ...audio, playing: false }))}
									onTimeUpdate={(e) => {
										dispatch(
											setAudio({
												...audio,
												currentProgress: e.currentTarget.currentTime,
											}),
										);
										handleBufferProgress(e);
									}}
								>
									<source type="audio/mpeg" src={audio.src} />
								</audio>
							) : null}
						</Card.Body>
					</Card>
				) : null}
			</div>
		</div>
	);
}

const stylesForCardShow = {
	position: "fixed",
	bottom: "20px",
	right: 0,
	left: 0,
	zIndex: 99999,

	margin: "auto",
};
const stylesForCard = {
	color: "#fff",
	border: "none",
	borderRadius: "10px",
	boxShadow: "0 0 10px rgba(77, 77, 77, 0.5)",
};
