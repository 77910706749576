import { Suspense, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../ScrollToTop";
import CustomLoading from "../common/CustomLoading";
import ErrorBoundary from "../common/Error";
import AudioPlayer from "./Audio";
import Footer from "./Footer";
import NavBar from "./NavBar";

const Layout = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		const lang = localStorage.getItem("lang");
		if (lang === "en") {
			document.body.style.fontFamily = "Onest";
		} else {
			document.body.style.fontFamily = "Noto Sans Bengali";
		}
	}, []);
	if (pathname === "/event") {
		return (
			<>
				<main className="main-wrapper">{children}</main>
			</>
		);
	}
	return (
		<ScrollToTop>
			<main className="main-wrapper">
				<>
					<Helmet>
						<title>Legalized Education Bangladesh Ltd.</title>
						<meta property="og:title" content="Legalized Education Bangladesh Ltd." />
						<meta property="og:type" content="website" />
						<meta property="og:image" content="%PUBLIC_URL%/images/logo/logo.png" />
						<meta property="og:url" content="https://legalizedbd.com/" />
						<meta
							name="description"
							content="Discover the world of legal education like you have never experienced before. Start your journey with us today."
						/>
						<meta name="viewport" content="width=device-width, initial-scale=1" />
						<meta name="theme-color" content="#000000" />
					</Helmet>
				</>
				<NavBar />
				<Suspense fallback={<CustomLoading height={80} />}>
					<ErrorBoundary>{children}</ErrorBoundary>
				</Suspense>
				<AudioPlayer />
				<Footer />
			</main>
		</ScrollToTop>
	);
};
export default Layout;
